import { React, useEffect, useRef, useState } from "react";
import $ from 'jquery';

import './Product.css';
import { Link } from "react-router-dom";
import { getFirestore, collection, getDocs, doc, query, orderBy, limit , where} from "firebase/firestore"; // 데이터 읽어오기

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay, Grid } from "swiper";	// 추가

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

SwiperCore.use([Navigation, Pagination, Autoplay, Grid]);

function Product(props) {
  const [Product_Metal1Db, SetProduct_Metal1Db]=useState(null);
  let Product_Metal1Data=[];
  const [Product_Metal1Loading, SetProduct_Metal1Loading]=useState(false);

  const [Product_Metal2Db, SetProduct_Metal2Db]=useState(null);
  let Product_Metal2Data=[];
  const [Product_Metal2Loading, SetProduct_Metal2Loading]=useState(false);

  const [Product_HeatDb, SetProduct_HeatDb]=useState(null);
  let Product_HeatData=[];
  const [Product_HeatLoading, SetProduct_HeatLoading]=useState(false);

  const [Product_SinkDb, SetProduct_SinkDb]=useState(null);
  let Product_SinkData=[];
  const [Product_SinkLoading, SetProduct_SinkLoading]=useState(false);

  const [PersonalPg, SetPersonalPg]=useState(false);

  const Metal1NaviPrev=useRef(null);
  const Metal1NaviNext=useRef(null);
  const Metal2NaviPrev=useRef(null);
  const Metal2NaviNext=useRef(null);
  const HeatNaviPrev=useRef(null);
  const HeatNaviNext=useRef(null);
  const SinkNaviPrev=useRef(null);
  const SinkNaviNext=useRef(null);

  const [TitleMetal, SetTitleMetal]=useState(true);
  const [TitleHeat, SetTitleHeat]=useState(true);
  const [TitleSink, SetTitleSink]=useState(true);

  const [MBMenuName, SetMBMenuName]=useState("Products");
  const ProductMenuMB = useRef(null);

  const [LightBoxIMG, SetLightBoxIMG]=useState(null);
  const LightBox=useRef(null);
  const LightBoxMB=useRef(null);

  // 컴포넌트가 마운트된 후에 실행되는 부분입니다.
  const imageElements = document.getElementsByTagName('img');
  
  useEffect(()=>{
    LoadingProductData_Metal1();
    LoadingProductData_Metal2();
    LoadingProductData_Heat();
    LoadingProductData_Sink();
    
  },[])

  useEffect(()=>{
    if(Product_Metal1Loading || Product_Metal2Loading || Product_HeatLoading || Product_SinkLoading){
      // 모든 img 태그에 이벤트 핸들러를 등록합니다.
      for (let i = 0; i < imageElements.length; i++) {
        imageElements[i].addEventListener('click', function (e) {
          SetLightBoxIMG(e.target.src);
          try {
            if(!LightBox.current.classList.contains('active')){
              LightBox.current.classList.add('active');
              // document.body.style.overflow = "hidden";
            } 
            
            if(!LightBoxMB.current.classList.contains('active')){
              LightBoxMB.current.classList.add('active');
              // document.body.style.overflow = "auto";
            }
          } catch (error) { }
        });
      }
    }
  },[Product_Metal1Loading, Product_Metal2Loading, Product_HeatLoading, Product_SinkLoading])

  async function LoadingProductData_Metal1(){
    SetProduct_Metal1Loading(false);
    const UserListRef = collection(props.db, "Product"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot1 = await getDocs(query(UserListRef, where("No", ">=", 0), where("Type", "==", "금속가공"), where("SubType", "==", "냉간단조")));
    Product_Metal1Data.length=0;
    await querySnapshot1.forEach((doc) => {
      Product_Metal1Data.push(doc.data());
    });
    SetProduct_Metal1Db(Product_Metal1Data);
    SetProduct_Metal1Loading(true);
  }

  async function LoadingProductData_Metal2(){
    SetProduct_Metal2Loading(false);
    const UserListRef = collection(props.db, "Product"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, where("No", ">=", 0), where("Type", "==", "금속가공"), where("SubType", "==", "절삭가공")));
    Product_Metal2Data.length=0;
    await querySnapshot.forEach((doc) => {
      Product_Metal2Data.push(doc.data());
    });
    SetProduct_Metal2Db(Product_Metal2Data);
    SetProduct_Metal2Loading(true);
  }

  async function LoadingProductData_Heat(){
    SetProduct_HeatLoading(false);
    const UserListRef = collection(props.db, "Product"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, where("No", ">=", 0), where("Type", "==", "열처리"), where("SubType", "==", "조질열처리")));
    Product_HeatData.length=0;
    await querySnapshot.forEach((doc) => {
      Product_HeatData.push(doc.data());
    });
    SetProduct_HeatDb(Product_HeatData);
    SetProduct_HeatLoading(true);
  }

  async function LoadingProductData_Sink(){
    SetProduct_SinkLoading(false);
    const UserListRef = collection(props.db, "Product"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, where("No", ">=", 0), where("Type", "==", "가전제품"), where("SubType", "==", "음식물처리기")));
    Product_SinkData.length=0;
    await querySnapshot.forEach((doc) => {
      Product_SinkData.push(doc.data());
    });
    SetProduct_SinkDb(Product_SinkData);
    SetProduct_SinkLoading(true);
  }

  function LightBoxClose(e) {
    if(e.currentTarget.parentNode.parentNode.classList.contains('active')){
      e.currentTarget.parentNode.parentNode.classList.remove('active');
      document.body.style.overflow = "auto";
    }
  }

  async function filteringMenu(e){
    await SetPersonalPg(false);
    SetTitleMetal(false);
    SetTitleHeat(false);
    SetTitleSink(false);
    SetProduct_Metal1Loading(false);
    SetProduct_Metal2Loading(false);
    SetProduct_HeatLoading(false);
    SetProduct_SinkLoading(false);
    
    Metal1NaviPrev.current.parentElement.style.display="none";
    Metal2NaviPrev.current.parentElement.style.display="none";
    HeatNaviPrev.current.parentElement.style.display="none";
    SinkNaviPrev.current.parentElement.style.display="none";

    SetMBMenuName(e.target.id);
    switch (e.target.id) {
      case "금속가공":
        SetTitleMetal(true);
        SetProduct_Metal1Loading(true);
        SetProduct_Metal2Loading(true);
        Metal1NaviPrev.current.parentElement.style.display="flex";
        Metal2NaviPrev.current.parentElement.style.display="flex";

      break;
    
      case "냉간단조":        
        SetPersonalPg(true);
        SetProduct_Metal1Loading(true);
      break;

      case "절삭가공":
        SetPersonalPg(true);
        SetProduct_Metal2Loading(true);
      break;

      case "열처리":
        HeatNaviPrev.current.parentElement.style.display="flex";
        SetTitleHeat(true);
        SetProduct_HeatLoading(true);
      break;

      case "조질열처리":
        SetPersonalPg(true);
        SetProduct_HeatLoading(true);
      break;

      case "가전제품":
        SinkNaviPrev.current.parentElement.style.display="flex";
        SetTitleSink(true);
        SetProduct_SinkLoading(true);
      break;

      case "싱크센스":
        SetPersonalPg(true);
        SetProduct_SinkLoading(true);
      break;

      case "Products":
        Metal1NaviPrev.current.parentElement.style.display="flex";
        Metal2NaviPrev.current.parentElement.style.display="flex";
        HeatNaviPrev.current.parentElement.style.display="flex";
        SinkNaviPrev.current.parentElement.style.display="flex";
        SetTitleMetal(true);
        SetTitleHeat(true);
        SetTitleSink(true);
        SetProduct_Metal1Loading(true);
        SetProduct_Metal2Loading(true);
        SetProduct_HeatLoading(true);
        SetProduct_SinkLoading(true);
      break;

      default:
        break;
    }

    if(ProductMenuMB.current.classList.contains('active')){
      ProductMenuMB.current.classList.remove('active');
    }

    $(window).scrollTop(0);
    $(".Menu").stop().slideDown(0);
  }

  function ProductMenuMBOpen(){
    if(!ProductMenuMB.current.classList.contains('active')){
      ProductMenuMB.current.classList.add('active');
    }
  }

  return (
    <div className="Product">
      <div className="Product_PC flex w-full flex-1 justify-center">
        <div className="Product_PC-IMG flex w-full items-center flex-col" ref={LightBox}>
          <div className="flex w-full justify-end">
            <i className="fa-solid fa-xmark" onClick={LightBoxClose}></i>
          </div>
            <img src={LightBoxIMG}></img>
        </div>
        <div className="Product_PC-products w-full con flex">
          <div className="MenuArea flex">
            <div className="MenuAreaList flex flex-col">
              <div><a href="#metal" id={"Products"} onClick={filteringMenu}>Products</a></div>
              <div className="flex h-8"></div>
              <ul>
                <li><a href="#metal" id={"금속가공"} onClick={filteringMenu}>금속가공</a>
                  <ul>
                    <li><a href="#coldforging" id={"냉간단조"} onClick={filteringMenu}>냉간단조</a></li>
                    <li><a href="#cutting" id={"절삭가공"} onClick={filteringMenu}>절삭가공</a></li>
                  </ul>
                </li>
                <li><a href="#heat" id={"열처리"} onClick={filteringMenu}>열처리</a>
                  <ul>
                    <li><a href="#heattreat" id={"조질열처리"} onClick={filteringMenu}>조질열처리</a></li>
                  </ul>
                </li>
                <li><a href="#appliance" id={"가전제품"} onClick={filteringMenu}>가전제품</a>
                  <ul>
                  <li><a href="#sink" id={"싱크센스"} onClick={filteringMenu}>싱크센스</a></li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          {PersonalPg ?
          <div id="ProductList" className="ProductList flex flex-1 flex-col">
            <div className="flex h-20"></div>
            {Product_Metal1Loading ? 
            <div className="flex w-full flex-col">
              <div className="flex">금속가공</div>
              <div className="flex h-8"></div>
              <div className="Middles flex">냉간단조</div>
              <div className="flex h-8"></div>
              <div className="List grid grid-cols-3 w-full gap-5">
                {Product_Metal1Db.map(product=>(
                <div className='Lists PersonalPG flex h-full flex-col justify-center items-center' key={product.index}>
                  <img className="flex justify-center" src={product.IMG} alt={product.Name}></img>
                  <div className="flex h-2"></div>
                  <div className="flex w-full justify-center">{product.Name}</div>
                  <div className="flex h-2"></div>
                </div>
                ))}
              </div>
            </div>
            : null}
            {Product_Metal2Loading ? 
            <div className="flex w-full flex-col">
              <div className="flex">금속가공</div>
              <div className="flex h-8"></div>
              <div className="Middles flex">절삭가공</div>
              <div className="flex h-8"></div>
              <div className="List grid grid-cols-3 w-full gap-5">
                {Product_Metal2Db.map(product=>(
                <div className='Lists PersonalPG flex h-full flex-col justify-center items-center' key={product.index}>
                  <img className="flex justify-center" src={product.IMG} alt={product.Name}></img>
                  <div className="flex h-2"></div>
                  <div className="flex w-full justify-center">{product.Name}</div>
                  <div className="flex h-2"></div>
                </div>
                ))}
              </div>
            </div>
            : null}
            {Product_HeatLoading ? 
            <div className="flex w-full flex-col">
              <div className="flex">열처리</div>
              <div className="flex h-8"></div>
              <div className="Middles flex">조질열처리</div>
              <div className="flex h-8"></div>
              <div className="List grid grid-cols-3 w-full gap-5">
                {Product_HeatDb.map(product=>(
                <div className='Lists PersonalPG flex h-full flex-col justify-center items-center' key={product.index}>
                  <img className="flex justify-center" src={product.IMG} alt={product.Name}></img>
                  <div className="flex h-2"></div>
                  <div className="flex w-full justify-center">{product.Name}</div>
                  <div className="flex h-2"></div>
                </div>
                ))}
              </div>
            </div>
            : null}
            {Product_SinkLoading ? 
            <div className="flex w-full flex-col">
              <div className="flex">가전제품</div>
              <div className="flex h-8"></div>
              <div className="Middles flex">싱크센스</div>
              <div className="flex h-8"></div>
              <div className="List grid grid-cols-3 w-full gap-5">
                {Product_SinkDb.map(product=>(
                <div className='Lists PersonalPG flex h-full flex-col justify-center items-center' key={product.index}>
                  <img className="flex justify-center" src={product.IMG} alt={product.Name}></img>
                  <div className="flex h-2"></div>
                  <div className="flex w-full justify-center">{product.Name}</div>
                  <div className="flex h-2"></div>
                </div>
                ))}
              </div>
            </div>
            : null}
            <div className="flex h-20"></div>
          </div> :
          <div className="ProductList flex flex-1 flex-col">
            {TitleMetal ? <div className="flex flex-col w-full">
              <div className="flex h-20"></div>
              <div className="flex">금속가공</div>
            </div> : null}
            <div className="flex w-full flex-col">
              {Product_Metal1Loading ? 
              <div className="flex flex-col">
                <div className="flex h-8"></div>
                <div className="Middles flex">냉간단조</div>
                <div className="flex h-8"></div>
              </div>
              : null}
              <div className="flex w-full items-center">    
              {Product_Metal1Loading ?
              <Swiper
              className='List flex flex-1 justify-center' 
              spaceBetween={20}
              slidesPerView={3}
              initialSlide={0}

              breakpoints={{
                1100 : {
                  slidesPerView : 2                  
                },
                1200 : {
                  slidesPerView : 3
                }
              }}

              navigation= {{ // 좌우 버튼 커스텀 
                prevEl: Metal1NaviPrev.current,
                nextEl: Metal1NaviNext.current
              }}

              // pagination={{ clickable: true }}
              // autoplay
              // ={{ delay: 100, disableOnInteraction: false }}

              loop={false}
              loopPreventsSliding={false}
              // onSlideChange={() => { console.log("onSlideChange"); }}
              // onSwiper={(swiper) => { console.log(swiper); }}
              >
                {Product_Metal1Db.map(product=>(
                <SwiperSlide className='Lists flex h-full justify-center items-center' key={product.index}>
                  <img className="flex justify-center" src={product.IMG} alt={product.Name}></img>
                  <div className="flex h-2"></div>
                  <div className="flex w-full justify-center">{product.Name}</div>
                  <div className="flex h-2"></div>
                </SwiperSlide>
                ))} 
              </Swiper>
              : null}
              <div className="swiper-navi flex flex-col justify-center items-center">
                <div className="swiper-button-prev flex" ref={Metal1NaviPrev}></div>
                <div className="swiper-button-next flex" ref={Metal1NaviNext}></div>
              </div>
              </div>
            </div>
            <div className="flex w-full flex-col">
              {Product_Metal2Loading ? 
              <div className="flex flex-col">
              <div className="flex h-20"></div>
              <div className="Middles flex">절삭가공</div>
              <div className="flex h-10"></div>
              </div> 
              : null }
              <div className="flex w-full items-center">
            {Product_Metal2Loading ?
              <Swiper
              className='List flex flex-1 justify-center' 
              spaceBetween={20}
              slidesPerView={3}
              breakpoints={{
                900 : {
                  slidesPerView : 2                  
                },
                1200 : {
                  slidesPerView : 3
                }
              }}
              
              navigation= {{ // 좌우 버튼 커스텀 
                prevEl: Metal2NaviPrev.current,
                nextEl: Metal2NaviNext.current
              }}

              // pagination={{ clickable: true }}
              // autoplay
              // ={{ delay: 100, disableOnInteraction: false }}

              loop={false}
              loopPreventsSliding={false}
              // onSlideChange={() => { console.log("onSlideChange"); }}
              // onSwiper={(swiper) => { console.log(swiper); }}
              >
                {Product_Metal2Db.map(product=>(
                <SwiperSlide className='Lists flex h-full justify-center items-center' key={product.index}>
                  <img className="flex justify-center" src={product.IMG} alt={product.Name}></img>
                  <div className="flex h-2"></div>
                  <div className="flex w-full justify-center">{product.Name}</div>
                  <div className="flex h-2"></div>
                </SwiperSlide>
                ))} 
              </Swiper>
              : null}
              <div className="swiper-navi flex flex-col justify-center items-center">
                <div className="swiper-button-prev flex" ref={Metal2NaviPrev}></div>
                <div className="swiper-button-next flex" ref={Metal2NaviNext}></div>
              </div>
              </div>
            </div>
            {TitleHeat ? 
            <div className="flex flex-col w-full">
              <div className="flex h-20"></div>
              <div className="flex">열처리</div>
            </div> : null}  
            <div className="flex w-full flex-col">
              {Product_HeatLoading ? 
              <div className="flex flex-col">
                <div className="flex h-8"></div>
                <div className="Middles flex">조질열처리</div>
                <div className="flex h-10"></div>
              </div> : null}
            <div className="flex w-full items-center">
            {Product_HeatLoading ? 
              <Swiper
              className='List flex flex-1 w-full justify-center' 
              spaceBetween={20}
              slidesPerView={3}
              breakpoints={{
                900 : {
                  slidesPerView : 2
                },
                1200 : {
                  slidesPerView : 3
                }
              }}
              
              navigation= {{ // 좌우 버튼 커스텀 
                prevEl: HeatNaviPrev.current,
                nextEl: HeatNaviNext.current
              }}

              // pagination={{ clickable: true }}
              // autoplay
              // ={{ delay: 100, disableOnInteraction: false }}

              loop={false}
              loopPreventsSliding={false}
              // onSlideChange={() => { console.log("onSlideChange"); }}
              // onSwiper={(swiper) => { console.log(swiper); }}
              >
                {Product_HeatDb.map(product=>(
                <SwiperSlide className='Lists flex h-full justify-center items-center' key={product.index}>
                  <img className="flex justify-center" src={product.IMG} alt={product.Name}></img>
                  <div className="flex h-2"></div>
                  <div className="flex w-full justify-center">{product.Name}</div>
                  <div className="flex h-2"></div>
                </SwiperSlide>
                ))} 
              </Swiper>
              : null}
              <div className="swiper-navi flex flex-col justify-center items-center">
                <div className="swiper-button-prev flex" ref={HeatNaviPrev}></div>
                <div className="swiper-button-next flex" ref={HeatNaviNext}></div>
              </div>
              </div>
            </div>
            {TitleSink ? 
            <div className="flex w-full flex-col">
              <div className="flex h-20"></div>
              <div className="flex">가전제품</div>
            </div> 
            : null}
            <div className="flex w-full flex-col">
              {Product_SinkLoading ? 
              <div className="flex flex-col">
                <div className="flex h-8"></div>
                <div className="Middles flex">싱크센스</div>
                <div className="flex h-10"></div>
                <div className="Sink_Context flex w-full">
                  <div className="flex">
                    <img src="https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/Products%2F%EA%B0%80%EC%A0%84%2F%EC%8B%B1%ED%81%AC%EC%84%BC%EC%8A%A42.png?alt=media&token=64e6cb7a-6d4f-4870-940a-46d88486ac4e" alt="싱크센스"></img>
                  </div>
                  <div className="flex w-14"></div>
                  <div className="flex flex-1 flex-col justify-end">
                    <div>
                      <span>싱크센스는 </span>
                      <p>독자개발한 분쇄와 거름망의 기능을 일체화한 분쇄필터를 탑재하여,<br></br>
                        제품 내부를 세척할 수 있어, 쾌적하고 위생적인 환경을 제공해주는,<br></br>
                        국내유일 1,2차 처리기 일체형 음식물처리기 입니다.
                      </p>
                    </div>
                    <div className="flex h-6"></div>
                    <div className="flex gap-4 ml-2">
                      <button className="flex">
                        <a href="https://www.sinksense.co.kr/brand" target="_blank" className="flex flex-1">제품세부정보</a>
                      </button>
                      <button className="flex">
                        <a href="https://smartstore.naver.com/sinksensemall/products/6735548289?" target="_blank" className="flex flex-1">구매하기</a>
                      </button>
                    </div>
                    <div className="flex h-16"></div>
                  </div>
                </div>
                <div className="flex h-14"></div>
              </div>
              : null}
              <div className="flex w-full items-center">    
              {Product_SinkLoading ? 
              <Swiper
              className='List flex flex-1 justify-center' 
              spaceBetween={20}
              slidesPerView={3}
              breakpoints={{
                1200 : {
                  slidesPerView : 3
                },
              }}
              
              navigation= {{ // 좌우 버튼 커스텀 
                prevEl: SinkNaviPrev.current,
                nextEl: SinkNaviNext.current
              }}

              // pagination={{ clickable: true }}
              // autoplay
              // ={{ delay: 100, disableOnInteraction: false }}

              loop={false}
              loopPreventsSliding={false}
              // onSlideChange={() => { console.log("onSlideChange"); }}
              // onSwiper={(swiper) => { console.log(swiper); }}
              >
                {Product_SinkDb.map(product=>(
                <SwiperSlide className='Lists flex h-full justify-center items-center' key={product.index}>
                  <img className="flex justify-center" src={product.IMG} alt={product.Name}></img>
                  <div className="flex h-2"></div>
                  <div className="flex w-full justify-center">{product.Name}</div>
                  <div className="flex h-2"></div>
                </SwiperSlide>
                ))} 
              </Swiper>
              : null}
              <div className="swiper-navi flex flex-col justify-center items-center">
                <div className="swiper-button-prev flex" ref={SinkNaviPrev}></div>
                <div className="swiper-button-next flex" ref={SinkNaviNext}></div>
              </div>
              </div>
            </div>
            <div className="flex h-20"></div>
          </div>
          }
        </div>
      </div>

      <div className="Product_MB flex flex-col w-full">
        <div className="Product_MB-IMG flex w-full items-center flex-col" ref={LightBoxMB}>
          <div className="flex w-full justify-end">
            <i className="fa-solid fa-xmark" onClick={LightBoxClose}></i>
          </div>
            <img src={LightBoxIMG}></img>
        </div>
        <div className="ProductBanner flex flex-col w-full">
          <div className="flex">Product</div>
          <div className="flex h-10"></div>
          <div className="MenuBar flex items-center" onClick={ProductMenuMBOpen}>
            <div className="flex flex-1">{MBMenuName}</div>
            <i className="flex fa-solid fa-circle"></i>
          </div>
          <div className="flex h-10"></div>
          <div className="MenuArea flex" ref={ProductMenuMB}>
            <div className="MenuAreaList flex flex-col">
              <div id={"Products"} onClick={filteringMenu} className="pb-4">Products</div>
              <ul>
                <li className="pl-2 pb-4"><a href="#metal" id={"금속가공"} onClick={filteringMenu}>금속가공</a>
                  <ul>
                    <li className="pl-4"><a href="#coldforging" id={"냉간단조"} onClick={filteringMenu}>냉간단조</a></li>
                    <li className="pl-4"><a href="#cutting" id={"절삭가공"} onClick={filteringMenu}>절삭가공</a></li>
                  </ul>
                </li>
                <li className="pl-2 pb-4"><a href="#heat" id={"열처리"} onClick={filteringMenu}>열처리</a>
                  <ul>
                    <li className="pl-4"><a href="#heattreat" id={"조질열처리"} onClick={filteringMenu}>조질열처리</a></li>
                  </ul>
                </li>
                <li className="pl-2 pb-4"><a href="#appliance" id={"가전제품"} onClick={filteringMenu}>가전제품</a>
                  <ul>
                  <li className="pl-4"><a href="#sink" id={"싱크센스"} onClick={filteringMenu}>싱크센스</a></li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="ProductList flex w-full flex-col">
          <div className="flex flex-col w-full">
          <div className="flex flex-1 flex-col">
          {TitleMetal ? 
          <div className="flex flex-col w-full">
            <div className="flex h-10"></div>
            <div className="flex">금속가공</div>
          </div> 
          : null}
          {Product_Metal1Loading ? 
          <div className="flex flex-col w-full">
            <div className="flex h-10"></div>
            <div className="Middles flex">냉간단조</div>
            <div className="flex h-5"></div>
            <div className="flex w-full items-center pb-6">
            {Product_Metal1Loading ? 
            <Swiper
            className='List flex flex-1 justify-center' 
            spaceBetween={20}
            slidesPerView={1}
            // navigation
            pagination={{
              type: 'progressbar',
              clickable : false,
            }}
            breakpoints={{
              400 : {
                slidesPerView : 2
              },
              600 : {
                slidesPerView : 3
              }
            }}
            // autoplay
            // ={{ delay: 100, disableOnInteraction: false }}
            loop={false}
            loopPreventsSliding={false}
            // onSlideChange={() => { console.log("slide change"); }}
            // onSwiper={(swiper) => { console.log(swiper); }}
            >
              {Product_Metal1Db.map(product=>(
              <SwiperSlide className='Lists flex justify-center items-center mb-6' key={product.index}>
                <img className="flex justify-center" src={product.IMG} alt={product.Name}></img>
                <div className="flex h-3"></div>
                <div className="flex flex-1 items-center justify-center">{product.Name}</div>
              </SwiperSlide>
              ))} 
            </Swiper>
            : null}
            </div>
            </div> 
          : null }
          </div>
          {Product_Metal2Loading ? 
          <div className="flex flex-1 flex-col">
            <div className="flex h-10"></div>
            <div className="Middles flex">절삭가공</div>
            <div className="flex h-5"></div>
            <div className="flex w-full items-center pb-6">
            <Swiper
            className='List flex flex-1 justify-center' 
            spaceBetween={20}
            slidesPerView={1}
            // navigation
            pagination={{
              type: 'progressbar',
              clickable : false,
            }}
            breakpoints={{
              400 : {
                slidesPerView : 2
              },
              600 : {
                slidesPerView : 3
              }
            }}
            // autoplay
            // ={{ delay: 100, disableOnInteraction: false }}
            loop={false}
            loopPreventsSliding={false}
            // onSlideChange={() => { console.log("slide change"); }}
            // onSwiper={(swiper) => { console.log(swiper); }}
            >
              {Product_Metal2Db.map(product=>(
              <SwiperSlide className='Lists flex h-full justify-center items-center mb-6' key={product.index}>
                <img className="flex justify-center" src={product.IMG} alt={product.Name}></img>
                <div className="flex h-3"></div>
                <div className="flex flex-1 justify-center">{product.Name}</div>
              </SwiperSlide>
              ))} 
            </Swiper>
            </div>
          </div>
          : null}
          </div>
          <div className="flex flex-1 flex-col">
            {TitleHeat ? 
            <div className="flex w-full flex-col">
              <div className="flex h-10"></div>
              <div className="flex">열처리</div>
            </div> 
            : null}
            {Product_HeatLoading ? 
            <div className="flex w-full flex-col">
              <div className="flex h-10"></div>
              <div className="Middles flex">조질열처리</div>
              <div className="flex h-5"></div>
              <div className="flex w-full items-center pb-6">
                <Swiper
                className='List flex flex-1 justify-center' 
                spaceBetween={20}
                slidesPerView={1}
                // navigation
                pagination={{
                  type: 'progressbar',
                  clickable : false,
                }}
                breakpoints={{
                  400 : {
                    slidesPerView : 2
                  },
                  600 : {
                    slidesPerView : 3
                  }
                }}
                // autoplay
                // ={{ delay: 100, disableOnInteraction: false }}
                loop={false}
                loopPreventsSliding={false}
                // onSlideChange={() => { console.log("slide change"); }}
                // onSwiper={(swiper) => { console.log(swiper); }}
                >
                  {Product_HeatDb.map(product=>(
                  <SwiperSlide className='Lists flex h-full justify-center items-center mb-6' key={product.index}>
                    <img className="flex justify-center" src={product.IMG} alt={product.Name}></img>
                    <div className="flex h-3"></div>
                    <div className="flex flex-1 justify-center">{product.Name}</div>
                  </SwiperSlide>
                  ))} 
                </Swiper>
              </div>
            </div> 
            : null}
          </div>
          <div className="flex flex-1 flex-col">
            {TitleSink ? 
            <div className="flex w-full flex-col">
              <div className="flex h-10"></div>
              <div className="flex">가전제품</div>
            </div> 
            : null}
            {Product_SinkLoading ? 
            <div className="flex w-full flex-col">
            <div className="flex h-10"></div>
            <div className="Middles flex">싱크센스</div>
            <div className="flex h-5"></div>
            <div className="Sink_Context flex flex-col w-full">
              <div className="flex w-full justify-center">
                <img src="https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/Products%2F%EA%B0%80%EC%A0%84%2F%EC%8B%B1%ED%81%AC%EC%84%BC%EC%8A%A42.png?alt=media&token=64e6cb7a-6d4f-4870-940a-46d88486ac4e" alt="싱크센스"></img>
              </div>
              <div className="flex h-4"></div>
              <div>
                <span>싱크센스는 </span>
                <p>독자개발한 분쇄와 거름망의 기능을 일체화한 분쇄필터를 탑재하여,<br></br>
                  제품 내부를 세척할 수 있어, 쾌적하고 위생적인 환경을 제공해주는,<br></br>
                  국내유일 1,2차 처리기 일체형 음식물처리기 입니다.
                </p>
              </div>
              <div className="flex h-6"></div>
              <div className="flex gap-4 ml-2">
                <button className="flex">
                  <a href="https://www.sinksense.co.kr/brand" target="_blank" className="flex flex-1">제품세부정보</a>
                </button>
                <button className="flex">
                  <a href="https://smartstore.naver.com/sinksensemall/products/6735548289?" target="_blank" className="flex flex-1">구매하기</a>
                </button>
              </div>
            </div>
            <div className="flex h-10"></div>
            <div className="flex w-full items-center pb-6">
            {Product_SinkLoading ? 
            <Swiper
            className='List flex flex-1 justify-center' 
            spaceBetween={20}
            slidesPerView={1}
            // navigation
            pagination={{
              type: 'progressbar',
              clickable : false,
            }}
            breakpoints={{
              600 : {
                slidesPerView : 2
              }
            }}
            // autoplay
            // ={{ delay: 100, disableOnInteraction: false }}
            loop={false}
            loopPreventsSliding={false}
            // onSlideChange={() => { console.log("slide change"); }}
            // onSwiper={(swiper) => { console.log(swiper); }}
            >
              {Product_SinkDb.map(product=>(
              <SwiperSlide className='Lists flex h-full justify-center items-center mb-6' key={product.index}>
                <img className="flex justify-center" src={product.IMG} alt={product.Name}></img>
                <div className="flex h-3"></div>
                <div className="flex flex-1 justify-center">{product.Name}</div>
              </SwiperSlide>
              ))} 
            </Swiper>
            : null}
            </div>
            </div> 
            : null}
          </div>
          <div className="flex h-20"></div>
        </div>
      </div>
    </div>
  );
}

export default Product;
