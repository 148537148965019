import { React, useEffect, useState, useRef } from "react";
import './Home.css';
import { Link } from "react-router-dom";
import { getFirestore, collection, getDocs, doc, query, orderBy, limit , where} from "firebase/firestore"; // 데이터 읽어오기
import $ from 'jquery';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";	// 추가

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

SwiperCore.use([Navigation, Pagination, Autoplay])

function Home(props) {

  const [HomeDb, SetHomeDb]=useState(null);
  let HomeData=[];
  const [HomeLoading, SetHomeLoading]=useState(false);
  const HomeProductPaN = useRef(null);
  const HomeNaviPrev = useRef(null);
  const HomeNaviNext = useRef(null);

  const HomeProductPaNMB = useRef(null);
  const HomeNaviPrevMB = useRef(null);
  const HomeNaviNextMB = useRef(null);

  const [Patent_MetalDb, SetPatent_MetalDb]=useState(null);
  let Patent_MetalData=[];
  const [Patent_MetalLoading, SetPatent_MetalLoading]=useState(false);

  const [NewsDb, SetNewsDb]=useState(null);
  let NewsData=[];
  const [NewsLoading, SetNewsLoading]=useState(false);

  //icons
  const [Iconup1 ,SetIconup1]=useState(true);

  const [CustomerDb, SetCustomerDb]=useState(null);
  let CustomerData=[];
  const [CustomerLoading, SetCustomerLoading]=useState(false);

  useEffect(()=>{
    LoadingHomeData();
    LoadingPatentData_Metal();
    LoadingCustomerData();
    LoadingNewsData();
  },[])

  async function LoadingHomeData(){
    SetHomeLoading(false);
    const UserListRef = collection(props.db, "Home"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot1 = await getDocs(query(UserListRef, orderBy("No","asc")));
    HomeData.length=0;
    await querySnapshot1.forEach((doc) => {
      HomeData.push(doc.data());
    });
    SetHomeDb(HomeData);
    SetHomeLoading(true);
  }

  async function LoadingPatentData_Metal(){
    SetPatent_MetalLoading(false);
    const UserListRef = collection(props.db, "Patent"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot1 = await getDocs(query(UserListRef, orderBy("No", "asc"), limit(12)));
    Patent_MetalData.length=0;
    await querySnapshot1.forEach((doc) => {
      Patent_MetalData.push(doc.data());
    });
    SetPatent_MetalDb(Patent_MetalData);
    SetPatent_MetalLoading(true);
  }  

  async function LoadingCustomerData(){
    SetCustomerLoading(false);
    const UserListRef = collection(props.db, "Customer"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, orderBy("No", "asc")));
    CustomerData.length=0;
    await querySnapshot.forEach((doc) => {
      CustomerData.push(doc.data());
    });
    SetCustomerDb(CustomerData);
    SetCustomerLoading(true);
  }

  async function LoadingNewsData(){
    SetNewsLoading(false);
    const UserListRef = collection(props.db, "News"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot1 = await getDocs(query(UserListRef, orderBy("No","asc"), limit(3)));
    NewsData.length=0;
    await querySnapshot1.forEach((doc) => {
      NewsData.push(doc.data());
    });
    SetNewsDb(NewsData);
    SetNewsLoading(true);
  }

  function ContentsClick(e) {
    if(e.currentTarget.nextElementSibling.classList.contains('active')){
      e.currentTarget.nextElementSibling.classList.remove('active');
      const icon = $(e.target).find('i'); // 클릭된 요소에서 아이콘을 찾습니다.
      icon.removeClass('rotated-icon');
    }
    else{
      e.currentTarget.nextElementSibling.classList.add('active');
      const icon = $(e.target).find('i'); // 클릭된 요소에서 아이콘을 찾습니다.
      icon.addClass('rotated-icon');
    }
  }

  return (
    <div className="Home">
      <div className="Home_PC flex flex-col w-full items-center">
        <div className="Home_PC-top con flex flex-col w-full items-center justify-center">
          <img src="./img/HomeMark.png"  alt="로고"></img>
          <div className="Text flex">&#34;창의 · 도전 · 실현&#34;</div>
          <div className="flex flex-col items-center"><p className="flex">금속가공업체 ㈜신아화스텍은</p>
            <p className="flex">기본에 충실하고 창의적인 도전정신으로,</p>
            <p className="flex">기술향상과 고품질을 실현하여</p> 
            <p className="flex">고객과 함께 발전하는 기업입니다.</p>
          </div>
        </div>
        <div className="Home_PC-Products flex flex-1 flex-col w-full items-center">
          <div className="flex con w-full flex-col">
          <div className="BigTitle flex w-full items-center">
            <div className="pr-4">Product</div>
            <div ref={HomeProductPaN} className="flex"></div>
            <div ref={HomeNaviPrev} className="NaviButton-left flex"><i className="fa-solid fa-chevron-left"></i></div>
            <div ref={HomeNaviNext} className="NaviButton-right flex"><i className="fa-solid fa-chevron-right"></i></div>
          </div>
          {HomeLoading ? 
          <Swiper
          className='Contents flex justify-center con' 
          // spaceBetween={8}
          slidesPerView={1}
          initialSlide={0}

          navigation= {{ // 좌우 버튼 커스텀 
            prevEl: HomeNaviPrev.current,
            nextEl: HomeNaviNext.current
          }}
          // touchRatio={0} // 드래기 넘기기 방지
          // speed={0}
          pagination={{el : HomeProductPaN.current, clickable : true}}
          autoplay = {{ delay: 5000, disableOnInteraction: false, pauseOnMouseEnter : true,}}
          loop={true}
          loopPreventsSliding={false}

          // onSlideChange={() => { console.log("slide change"); }}
          // onSwiper={(swiper) => { console.log(swiper); }}

          >
            {HomeDb.map(home=>(
            <SwiperSlide>
              <div className="Contents-List flex w-full con">
                <div className="MenuSide flex w-full h-full justify-end items-center">
                  <img src={home.IMG} alt="이미지"></img>
                </div>
                <div className="ContextSide flex flex-1 flex-col bg-black">
                  <div className="HomeName flex">주요생산품&#40;{home.Name}&#41;</div>
                  {home.SubName!=="" ? <div className="HomeNameSub flex">{home.SubName}</div> : null}
                  <div className="HomeDetail flex">{home.Detail}</div>
                  {home.TypeTitle1!=="" ? 
                    <div className="HomeType flex w-full gap-4">
                      {home.TypeTitle1!=="" ? 
                      <div className="flex flex-col">
                        {/* <a href={home.TypeLink1} className="flex flex-1 flex-col"> */}
                          <div>Type 1</div>
                          <div>{home.TypeTitle1}</div>
                          <div className="TypeDetail">{home.TypeDetail1}</div>
                        {/* </a> */}
                      </div>
                      : null}
                      {home.TypeTitle2!=="" ? 
                      <div className="flex flex-col">
                        {/* <a href={home.TypeLink2} className="flex flex-1 flex-col"> */}
                          <div>Type 2</div>
                          <div>{home.TypeTitle2}</div>
                          <div className="TypeDetail">{home.TypeDetail2}</div>
                        {/* </a> */}
                      </div>
                      : null}
                    </div>
                  : null}
                  
                  {home.Link1!=="" ? 
                  <div className="HomeLink flex w-full gap-6">
                    <button><a className="flex flex-1" href={home.Link1}>{home.LinkName1}</a></button>
                    <button><a className="flex flex-1" href={home.Link2} target="_blank">{home.LinkName2}</a></button>
                  </div>
                  : null}
                  </div>
              </div>
            </SwiperSlide>
            ))}
          </Swiper>
          : null}
          </div>
        </div>
        <div className="Home_PC-Patent flex w-full justify-center">
          <div className="flex flex-col w-full con">
            <div className="BigTitle flex w-full items-center">Patent</div>
            <div className="Contents flex flex-col">
              <div className="flex w-full">
                ㈜신아화스텍은 연구개발 전담부서를 설립 및 운영중이며,<br></br>
                IATF16949 ISO14000 인증 및 SQ, MQ품질 인증 등을 획득하였습니다.</div>   
                <div className="Contents-IMGs flex flex-1 flex-col mt-14 items-center justify-center">
                  {Patent_MetalLoading ?
                  <div className="Contents-IMG grid grid-cols-6 gap-6">
                  {Patent_MetalDb.map(patent=>(
                    <div className="flex justify-center items-center mb-2" key={patent.index}>
                      <img src={patent.IMG} alt={patent.Name}></img>
                    </div>
                  ))}
                  </div>
                  : null}
                  <Link to="/Patent" className="flex justify-center items-center">See More...</Link>
                </div>
            </div>
          </div>
        </div>
        <div className="Home_PC-Trade flex w-full justify-center">
          <div className="flex flex-col w-full con">
            <div className="BigTitle flex w-full items-center">주요거래처</div>
            <div className="Contents flex flex-col">
              {CustomerLoading ?
              <Swiper
              className='companyList flex flex-1 justify-center' 
              spaceBetween={20}
              slidesPerView={4}
              slidesPerGroup={4}
              autoplay
              ={{ delay: 3000, disableOnInteraction: false }}
              loop={true}
              loopPreventsSliding={false}
              // onSlideChange={() => { console.log("slide change"); }}
              // onSwiper={(swiper) => { console.log(swiper); }}
              >
                {CustomerDb.map(customer=>(
                  <SwiperSlide className="CompanyLists flex flex-col items-center mb-2" key={customer.index}>
                    <div className="flex flex-1 justify-center items-center">
                      <img src={customer.IMG} alt={customer.Name}></img>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              : null}
            </div>
          </div>
        </div>
        <div className="Home_PC-Contact flex flex-col w-full items-center">
          <div className="BigName flex">Contact Us</div>
          <div className="flex">&#40;주&#41; 신아화스텍은 언제나 고객만족을 위해 노력합니다.</div>
          <i className="fa-solid fa-angle-down flex pb-4"></i>
          <Link to="/Contact" className="flex">Go to Link</Link>
        </div>
        <div className="Home_PC-News flex w-full justify-center">
          <div className="flex flex-col w-full con">
            <div className="BigTitle flex w-full items-center">News</div>
            <div className="Contents flex flex-col">
            {NewsLoading && NewsDb.length ?
              <div className="flex flex-1 flex-col">
              {NewsDb.map((News)=>(
                <div className="flex flex-1 flex-col items-center" key={News.index}>
                  <div className="HeadLines flex w-full items-center gap-4" onClick={ContentsClick}>
                    <div className="flex justify-center items-center">{News.Date}</div>
                    <div className="flex items-center">{News.Title}</div>
                    <i className="fa-solid fa-angle-up"></i>
                  </div>
                  <div className="NContext flex w-full flex-col">
                    {News.Contents.split('<br>').map((line, index) => (
                    <p className='flex w-full flex-wrap' key={index}>{line}</p>
                    ))}
                  </div>
                </div>
                ))} 
              </div>
            : null }
              <div className="flex flex-1 justify-center mt-10"><Link to="/News">See More...</Link></div>
            </div>
            <div className="flex h-20"></div>
          </div>      
        </div>
      </div>
      <div className="Home_MB flex flex-col w-full items-center">
        <div className="Home_MB-top flex flex-col w-full items-center justify-center">
          <img src="./img/HomeMark.png"  alt="로고"></img>
          <div className="Text flex">&#34;창의 · 도전 · 실현&#34;</div>
          <div className="flex flex-col items-center"><p className="flex">금속가공업체 ㈜신아화스텍은</p>
            <p className="flex">기본에 충실하고 창의적인 도전정신으로,</p>
            <p className="flex">기술향상과 고품질을 실현하여</p> 
            <p className="flex">고객과 함께 발전하는 기업입니다.</p>
          </div>
        </div>
        <div className="Home_MB-Products flex flex-1 flex-col w-full items-center">
          <div className="flex w-full flex-col">
          <div className="BigTitle flex w-full items-center">
            <div className="pr-4">Product</div>
            <div ref={HomeProductPaNMB} className="flex"></div>
            <div ref={HomeNaviPrevMB} className="NaviButton-left flex"><i className="fa-solid fa-chevron-left"></i></div>
            <div ref={HomeNaviNextMB} className="NaviButton-right flex"><i className="fa-solid fa-chevron-right"></i></div>
          </div>
          {HomeLoading ? 
          <Swiper
          className='Contents flex flex-1' 
          // spaceBetween={8}
          slidesPerView={1}
          initialSlide={0}

          navigation= {{ // 좌우 버튼 커스텀 
            prevEl: HomeNaviPrevMB.current,
            nextEl: HomeNaviNextMB.current
          }}
          // touchRatio={0} // 드래기 넘기기 방지
          // speed={0}
          pagination={{el : HomeProductPaNMB.current, clickable : true}}
          autoplay = {{ delay: 5000, disableOnInteraction: false, pauseOnMouseEnter : true,}}
          loop={true}
          loopPreventsSliding={false}

          // onSlideChange={() => { console.log("slide change"); }}
          // onSwiper={(swiper) => { console.log(swiper); }}

          >
            {HomeDb.map(home=>(
            <SwiperSlide>
              <div className="Contents-List flex flex-col w-full">
                <div className="MenuSide flex flex-1 justify-center items-center">
                  <img src={home.IMG} alt="이미지"></img>
                </div>
                <div className="ContextSide flex flex-1 flex-col bg-black">
                  <div className="HomeName flex">주요생산품&#40;{home.Name}&#41;</div>
                  {home.SubName!=="" ? <div className="HomeNameSub flex">{home.SubName}</div> : null}
                  <div className="HomeDetail flex">{home.Detail}</div>
                  {home.TypeTitle1!=="" ? 
                    <div className="HomeType flex flex-col w-full gap-4">
                      {home.TypeTitle1!=="" ? 
                      <div className="flex flex-col">
                        {/* <a href={home.TypeLink1} className="flex flex-1 flex-col"> */}
                          <div>Type 1</div>
                          <div>{home.TypeTitle1}</div>
                          <div className="TypeDetail">{home.TypeDetail1}</div>
                        {/* </a> */}
                      </div>
                      : null}
                      {home.TypeTitle2!=="" ? 
                      <div className="flex flex-col">
                        {/* <a href={home.TypeLink2} className="flex flex-1 flex-col"> */}
                          <div>Type 2</div>
                          <div>{home.TypeTitle2}</div>
                          <div className="TypeDetail">{home.TypeDetail2}</div>
                        {/* </a> */}
                      </div>
                      : null}
                    </div>
                  : null}
                  
                  {home.Link1!=="" ? 
                  <div className="HomeLink flex w-full justify-center gap-6">
                    <button><a className="flex flex-1" href={home.Link1}>{home.LinkName1}</a></button>
                    <button><a className="flex flex-1" href={home.Link2} target="_blank">{home.LinkName2}</a></button>
                  </div>
                  : null}
                  </div>
              </div>
            </SwiperSlide>
            ))}
          </Swiper>
          : null}
          </div>
        </div>
        <div className="Home_MB-Patent flex w-full justify-center">
          <div className="flex flex-col w-full">
            <div className="BigTitle flex w-full items-center">Patent</div>
            <div className="Contents flex flex-col">
              <div className="flex w-full">
                ㈜신아화스텍은 연구개발 전담부서를 설립 및 운영중이며,<br></br>
                IATF16949 ISO14000 인증 및 SQ, MQ품질 인증 등을 획득하였습니다.</div>   
                <div className="Contents-IMGs flex flex-1 flex-col items-center justify-center">
                  {Patent_MetalLoading ?
                  <div className="Contents-IMG grid grid-cols-4 gap-2">
                  {Patent_MetalDb.map(patent=>(
                    <div className="flex justify-center items-center mb-2" key={patent.index}>
                      <img src={patent.IMG} alt={patent.Name}></img>
                    </div>
                  ))}
                  </div>
                  : null}
                  <Link to="/Patent" className="flex justify-center items-center">See More...</Link>
                </div>
            </div>
          </div>
        </div>
        <div className="Home_MB-Trade flex w-full justify-center">
          <div className="flex flex-col w-full">
            <div className="BigTitle flex w-full items-center">주요거래처</div>
            <div className="Contents w-full flex flex-col">
              {CustomerLoading ?
              <Swiper
              className='companyList flex flex-1' 
              spaceBetween={20}
              slidesPerView={2}
              slidesPerGroup={2}

              breakpoints={{
                600:{
                  slidesPerView:3,
                  slidesPerGroup:3
                },
              }}
              autoplay
              ={{ delay: 3000, disableOnInteraction: false }}
              loop={true}
              loopPreventsSliding={false}
              // onSlideChange={() => { console.log("slide change"); }}
              // onSwiper={(swiper) => { console.log(swiper); }}
              >
                {CustomerDb.map(customer=>(
                  <SwiperSlide className="CompanyLists flex flex-col items-center" key={customer.index}>
                    <div className="flex flex-1">
                      <img src={customer.IMG} alt={customer.Name}></img>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              : null}
            </div>
          </div>
        </div>
        <div className="Home_MB-Contact flex flex-col w-full items-center">
          <div className="BigName flex">Contact Us</div>
          <div className="flex justify-center">&#40;주&#41; 신아화스텍은 언제나 고객만족을 위해 노력합니다.</div>
          <i className="fa-solid fa-angle-down flex pb-2"></i>
          <Link to="/Contact" className="flex">Go to Link</Link>
        </div>
        <div className="Home_MB-News flex w-full justify-center">
          <div className="flex flex-col w-full">
            <div className="BigTitle flex w-full items-center">News</div>
            <div className="Contents flex flex-col">
            {NewsLoading && NewsDb.length ?
              <div className="flex w-full flex-col">
              {NewsDb.map((News)=>(
                <div className="flex flex-1 flex-col items-center flex-col" key={News.index}>
                  <div className="HeadLines flex w-full items-center gap-4" onClick={ContentsClick}>
                    <div className="flex justify-center items-center">{News.Date}</div>
                    <div className="flex items-center">{News.Title}</div>
                    <i className="fa-solid fa-angle-up"></i>
                  </div>
                  <div className="NContext flex w-full flex-col">
                    {News.Contents.split('<br>').map((line, index) => (
                    <p className='flex w-full flex-wrap' key={index}>{line}</p>
                    ))}
                  </div>
                </div>
                ))} 
              </div>
            : null }
              <div className="flex flex-1 justify-center mt-10"><Link to="/News">See More...</Link></div>
            </div>
            <div className="flex h-20"></div>
          </div>      
        </div>
      </div>
    </div>
  );
}

export default Home;
