import { React, useEffect, useRef, useState } from "react";
import './Archaive.css';
import { Link } from "react-router-dom";
import { getFirestore, collection, getDocs, doc, query, orderBy, limit , where} from "firebase/firestore"; // 데이터 읽어오기

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";	// 추가

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

SwiperCore.use([Navigation, Pagination, Autoplay])

function Archaive(props) {
  const [ArchaiveDb, SetArchaiveDb]=useState(null);
  let ArchaiveData=[];
  const [ArchaiveLoading, SetArchaiveLoading]=useState(false);

  //icons
  const [Iconup1 ,SetIconup1]=useState(true);

  // for paging
  const [ArchaivePgAll, SetArchaivePgAll]=useState(null);
  const ArchaiveFNaviPrev=useRef(null);
  const ArchaiveFNaviNext=useRef(null);
  const ArchaiveFPagination=useRef(null);

  const ArchaiveFNaviPrevMB=useRef(null);
  const ArchaiveFNaviNextMB=useRef(null);
  const [ArchaiveF1st, SetArchaiveF1st]=useState(1);
  const [ArchaiveFAllP, SetArchaiveFAllP]=useState(null);

  const paginationArchaive = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
    el : ArchaiveFPagination.current,
  };

  useEffect(()=>{
    LoadingArchaiveData();
  },[])

  useEffect(()=>{
    if(ArchaiveDb!==null && ArchaiveDb!==undefined){
      if(ArchaiveDb.length===0){
        ArchaiveFNaviPrev.current.parentElement.parentElement.style.display="none";
        ArchaiveFNaviPrevMB.current.parentElement.parentElement.style.display="none";
      }
      else{
        ArchaiveFNaviPrev.current.parentElement.parentElement.style.display="flex";
        ArchaiveFNaviPrevMB.current.parentElement.parentElement.style.display="flex";
      }
    }
  },[ArchaiveDb])

  async function LoadingArchaiveData(){
    SetArchaiveLoading(false);
    const UserListRef = collection(props.db, "Archaive"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot1 = await getDocs(query(UserListRef, orderBy("No","asc")));
    ArchaiveData.length=0;
    await querySnapshot1.forEach((doc) => {
      ArchaiveData.push(doc.data());
    });
    SetArchaiveDb(ArchaiveData);
    await PagingArchaive();
    SetArchaiveLoading(true);
  }
  
  function PagingArchaive() {
    let array =[];
    
    for (let i = 0; i <ArchaiveData.length; i+=5) {
      array.push({id:i});
    }
    SetArchaivePgAll(array);
    SetArchaiveFAllP(array.length);
  }

  function MinusArchaivePage(params) {
    if(ArchaiveF1st>1){
      SetArchaiveF1st(ArchaiveF1st-1);
    }
  }

  function PlusArchaivePage(params) {
    if(ArchaiveF1st<ArchaiveFAllP){
      SetArchaiveF1st(ArchaiveF1st+1);
    }
  }

  function ContentsClick(e) {
    if(e.currentTarget.nextElementSibling.classList.contains('active')){
      e.currentTarget.nextElementSibling.classList.remove('active');
      SetIconup1(true);
    }
    else{
      e.currentTarget.nextElementSibling.classList.add('active');
      SetIconup1(false);
    }
  }

  return (
    <div className="Archaive">
      <div className="Archaive_PC flex w-full justify-center">
        <div className="Archaive_PC-Archaive flex flex-col w-full items-center">
          <div className="MenuArea flex items-center">
            <div>홍보자료실</div>
          </div>
          <div className="ArchaiveList flex flex-col flex-1">
          {ArchaiveLoading && ArchaiveDb.length ?
            <div className="flex w-full flex-col">
              <Swiper
              className='List flex justify-center' 
              // spaceBetween={8}
              slidesPerView={1}
              initialSlide={0}

              navigation= {{ // 좌우 버튼 커스텀 
                prevEl: ArchaiveFNaviPrev.current,
                nextEl: ArchaiveFNaviNext.current
              }}
              touchRatio={0} // 드래기 넘기기 방지
              speed={0}
              pagination={paginationArchaive}
              // autoplay
              // ={{ delay: 100, disableOnInteraction: false }}
              loop={false}
              loopPreventsSliding={false}
              // onSlideChange={() => { console.log("slide change"); }}
              // onSwiper={(swiper) => { console.log(swiper); }}
              >
                {ArchaivePgAll.map((arr)=>(
                <SwiperSlide className='Lists flex w-full flex-col' key={arr.index}>
                {ArchaiveDb.filter((Archaive)=> Archaive.id > arr.id && Archaive.id<=(arr.id+5) ).map((Archaive)=>(
                  <div className="flex flex-1 flex-col justify-center items-center" key={Archaive.index}>
                    <div className="HeadLines flex w-full" onClick={ContentsClick}>
                      <div className="flex w-20 justify-center items-center">{Archaive.Date}</div>
                      <div className="flex w-20"></div>
                      <div className="flex">{Archaive.Title}</div>
                      <div className="flex items-center">
                        { Iconup1 ?
                          <i className="fa-solid fa-angle-up"></i>
                          : <i className="fa-solid fa-angle-down"></i>
                        }
                      </div>
                    </div>
                    <div className="Contents flex w-full flex-col">
                      <div className="flex">{Archaive.Contents}</div>
                      <div className="flex">{Archaive.DownLoadLink}</div>
                    </div>
                  </div>
                  ))} 
                </SwiperSlide> 
                ))}
              </Swiper>         
            </div>
          : <div className="flex w-full flex-col justify-center items-center">
            <i className="fa-solid fa-exclamation flex"></i>
            <div className="flex">등록된 글이 없습니다.</div>
            </div>}
            <div className="PagingTool flex w-full h-10">
              <div className="flex flex-1"></div>
              <div className="swiper-buttonPrev flex justify-end items-center" >
                <i className="fa-solid fa-chevron-left" ref={ArchaiveFNaviPrev}></i>
              </div>
              <div className="flex flex-1 justify-center items-center" ref={ArchaiveFPagination}></div>
              <div className="swiper-buttonNext flex justify-start items-center" >
                <i className="fa-solid fa-chevron-right" ref={ArchaiveFNaviNext}></i>
              </div>
              <div className="flex flex-1"></div>
            </div>
            <div className="flex h-10"></div>            
            <div className="flex h-20"></div>   
          </div>
        </div>
      </div>

      <div className="Archaive_MB flex flex-col">
        <div className="ArchaiveBanner flex flex-col w-full">
          <div className="MenuArea flex">
            <div className="MenuAreaList flex flex-col">
              <div>홍보자료실</div>
            </div>
          </div>
        </div>
        <div className="flex h-14"></div>
        <div className="ArchaiveList flex flex-col flex-1">
        {ArchaiveLoading && ArchaiveDb.length ?
          <div className="flex w-full flex-col">
            <Swiper
            className='List flex justify-center' 
            // spaceBetween={8}
            slidesPerView={1}
            initialSlide={0}

            navigation= {{ // 좌우 버튼 커스텀 
              prevEl: ArchaiveFNaviPrevMB.current,
              nextEl: ArchaiveFNaviNextMB.current
            }}
            touchRatio={0} // 드래기 넘기기 방지
            speed={0}
            // autoplay
            // ={{ delay: 100, disableOnInteraction: false }}
            loop={false}
            loopPreventsSliding={false}
            // onSlideChange={() => { console.log("slide change"); }}
            // onSwiper={(swiper) => { console.log(swiper); }}
            >
              {ArchaivePgAll.map((arr)=>(
              <SwiperSlide className='Lists flex w-full flex-col' key={arr.index}>
              {ArchaiveDb.filter((Archaive)=> Archaive.id > arr.id && Archaive.id<=(arr.id+5) ).map((Archaive)=>(
                <div className="flex flex-1 flex-col justify-center items-center" key={Archaive.index}>
                  <div className="flex w-full">
                    <div className="flex w-20 items-center">{Archaive.Date}</div>
                  </div>
                  <div className="HeadLines flex w-full" onClick={ContentsClick}>
                    <div className="flex">{Archaive.Title}</div>
                    <div className="flex flex-1 justify-end items-center">
                      { Iconup1 ?
                        <i className="fa-solid fa-angle-up"></i>
                        : <i className="fa-solid fa-angle-down"></i>
                      }
                    </div>
                  </div>
                  <div className="Contents flex w-full">
                    {Archaive.Contents}
                  </div>
                </div>
                ))} 
              </SwiperSlide> 
              ))}
            </Swiper>          
          </div>
            : <div className="flex w-full flex-col justify-center items-center">
            <i className="fa-solid fa-exclamation flex"></i>
            <div className="flex">등록된 글이 없습니다.</div>
            </div> }
            <div className="PagingTool flex w-full h-10">
              <div className="flex flex-1"></div>
              <div className="swiper-buttonPrev flex justify-end items-center" >
                <i className="fa-solid fa-chevron-left" ref={ArchaiveFNaviPrevMB} onClick={MinusArchaivePage}></i>
              </div>
              <div className="flex flex-1 justify-center items-center">
                {ArchaiveF1st} / {ArchaiveFAllP}
              </div>
              <div className="swiper-buttonNext flex justify-start items-center" >
                <i className="fa-solid fa-chevron-right" ref={ArchaiveFNaviNextMB} onClick={PlusArchaivePage}></i>
              </div>
              <div className="flex flex-1"></div>
            </div>  
          <div className="flex h-10"></div>            
          <div className="flex h-20"></div>
        </div>
      </div>

    </div>
  );
}

export default Archaive;
