import { React, useEffect, useRef, useState } from "react";
import './Patent.css';
import { Link } from "react-router-dom";
import { getFirestore, collection, getDocs, doc, query, orderBy, limit , where} from "firebase/firestore"; // 데이터 읽어오기

function Patent(props) {
  const [Patent_MetalDb, SetPatent_MetalDb]=useState(null);
  let Patent_MetalData=[];
  const [Patent_MetalLoading, SetPatent_MetalLoading]=useState(false);

  const [LightBoxIMG, SetLightBoxIMG]=useState(null);
  const LightBox=useRef(null);
  const LightBoxMB=useRef(null);
  const imageElements = document.getElementsByTagName('img');

  useEffect(()=>{
    LoadingPatentData_Metal();
  },[])

  useEffect(()=>{
    if(Patent_MetalLoading){
      // 모든 img 태그에 이벤트 핸들러를 등록합니다.
      for (let i = 0; i < imageElements.length; i++) {
        imageElements[i].addEventListener('click', function (e) {
          SetLightBoxIMG(e.target.src);
          try {
            if(!LightBox.current.classList.contains('active')){
              LightBox.current.classList.add('active');
              // document.body.style.overflow = "hidden";
            } 
            
            if(!LightBoxMB.current.classList.contains('active')){
              LightBoxMB.current.classList.add('active');
              // document.body.style.overflow = "auto";
            }
          } catch (error) { }
        });
      }
    }
  },[Patent_MetalLoading])

  async function LoadingPatentData_Metal(){
    SetPatent_MetalLoading(false);
    const UserListRef = collection(props.db, "Patent"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot1 = await getDocs(query(UserListRef, orderBy("No", "asc")));
    Patent_MetalData.length=0;
    await querySnapshot1.forEach((doc) => {
      Patent_MetalData.push(doc.data());
    });
    SetPatent_MetalDb(Patent_MetalData);
    SetPatent_MetalLoading(true);
  }

  function LightBoxClose(e) {
    if(e.currentTarget.parentNode.parentNode.classList.contains('active')){
      e.currentTarget.parentNode.parentNode.classList.remove('active');
      document.body.style.overflow = "auto";
    }
  }

  return (
    <div className="Patent">
      <div className="Patent_PC flex w-full justify-center">
        <div className="Patent-IMG flex w-full items-center flex-col" ref={LightBox}>
          <div className="flex w-full justify-end">
            <i className="fa-solid fa-xmark" onClick={LightBoxClose}></i>
          </div>
            <img src={LightBoxIMG}></img>
        </div>
        <div className="Patent_PC-Patent flex con w-full">
          <div className="MenuArea flex">
            <div className="MenuAreaList flex flex-col">
              <div>특허·인증</div>
            </div>
          </div>
          <div className="Patents flex flex-col">
            <div className="PatentList flex flex-col">
              {Patent_MetalLoading ?
              <div className="grid grid-cols-4 gap-5">
              {Patent_MetalDb.map(patent=>(
                <div className="List flex flex-col items-center mb-2" key={patent.index}>
                  <div className="flex w-full justify-center items-center">
                    <img src={patent.IMG} alt={patent.Name}></img>
                  </div>
                  <div className="flex w-full mt-4 mb-1">{patent.Name}</div>
                  <div className="flex w-full mt-1 mb-1">인증기관 : {patent.Agency}</div>
                </div>
              ))}
              </div>
            : null}
              <div className="flex h-20"></div>
              <div className="flex h-10"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="Patent_MB flex flex-col">
        <div className="Patent-IMG flex w-full items-center flex-col" ref={LightBoxMB}>
          <div className="flex w-full justify-end">
            <i className="fa-solid fa-xmark" onClick={LightBoxClose}></i>
          </div>
            <img src={LightBoxIMG}></img>
        </div>
        <div className="PatentBanner flex flex-col w-full">
          <div className="MenuArea flex">
            <div className="MenuAreaList flex flex-col">
              <div>특허·인증</div>
            </div>
          </div>
        </div>
        <div className="flex h-14"></div>
        <div className="PatentList flex flex-col">
          {Patent_MetalLoading ?
            <div className="grid grid-cols-2 gap-4">
            {Patent_MetalDb.map(patent=>(
              <div className="List flex flex-col items-center mb-2" key={patent.index}>
                <div className="flex w-full justify-center items-center">
                  <img src={patent.IMG} alt={patent.Name}></img>
                </div>
                <div className="flex w-full mt-4 mb-1">{patent.Name}</div>
                <div className="flex w-full mt-1 mb-1">인증기관 : {patent.Agency}</div>
              </div>
            ))}
            </div>
          : null}
          <div className="flex h-20"></div>
          <div className="flex h-10"></div>
        </div>
      </div>

    </div>
  );
}

export default Patent;
