import { React, useEffect, useRef, useState } from "react";
import './Process.css';
import { Link } from "react-router-dom";
import { getFirestore, collection, getDocs, doc, query, orderBy, limit , where} from "firebase/firestore"; // 데이터 읽어오기
import ReactPlayer from 'react-player'

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";	// 추가

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

SwiperCore.use([Navigation, Pagination, Autoplay])



function Process(props) {
  const [Process_MetalDb, SetProcess_MetalDb]=useState(null);
  let Process_MetalData=[];
  const [Process_MetalLoading, SetProcess_MetalLoading]=useState(false);

  const [Process_HeatDb, SetProcess_HeatDb]=useState(null);
  let Process_HeatData=[];
  const [Process_HeatLoading, SetProcess_HeatLoading]=useState(false);

  useEffect(()=>{
    LoadingProcessData_Metal();
    LoadingProcessData_Heat();
  },[])

  async function LoadingProcessData_Metal(){
    SetProcess_MetalLoading(false);
    const UserListRef = collection(props.db, "Process"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot1 = await getDocs(query(UserListRef, where("No", ">=", 0), where("Type", "==", "금속가공")));
    Process_MetalData.length=0;
    await querySnapshot1.forEach((doc) => {
      Process_MetalData.push(doc.data());
    });
    SetProcess_MetalDb(Process_MetalData);
    SetProcess_MetalLoading(true);
  }

  async function LoadingProcessData_Heat(){
    SetProcess_HeatLoading(false);
    const UserListRef = collection(props.db, "Process"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, where("No", ">=", 0), where("Type", "==", "열처리")));
    Process_HeatData.length=0;
    await querySnapshot.forEach((doc) => {
      Process_HeatData.push(doc.data());
    });
    SetProcess_HeatDb(Process_HeatData);
    SetProcess_HeatLoading(true);
  }

  return (
    <div className="Process">
      <div className="Process_PC flex w-full justify-center">
        <div className="Process_PC-Process flex con w-full">
          <div className="MenuArea flex">
            <div className="MenuAreaList flex flex-col">
              <div>공정소개</div>
              <div className="flex h-8"></div>
              <ul>
                <li><a href="#metal">금속가공</a></li>
                <li><a href="#heat">열처리</a></li>
              </ul>
            </div>
          </div>
          <div className="ProcessList flex flex-1 flex-col">
            <div className="flex w-full flex-col">
              <div className="flex h-14"></div>
              <p>신아화스텍은 CNC선반 자동화와 냉간 단조 설비의 도입으로 지속적인 고품질 유지 및,<br></br>
                  별도의 열처리지점을 운영하여, 조질열처리를 통해 고품질의 제품들을 생산합니다.</p>
              <div className="flex h-20"><a name="metal"></a></div>
              <div className="flex">금속가공</div>
              <div className="flex h-10"></div>
              {Process_MetalLoading ? 
              <div className="flex w-full flex-col">
                <div className="flex w-full gap-6">
                  <div className="flex flex-1 grid grid-cols-4 gap-y-6">
                  {Process_MetalDb.map(process=>(
                    <div className='Lists flex flex-1 justify-center items-center' key={process.index}>
                      <div className="flex flex-col items-center">
                        <div className="ListsIMG flex flex-col justify-center items-center">
                          <img className="flex justify-center" src={process.IMG} alt={process.Name}></img>
                        </div>
                        <div className="flex h-3"></div>
                        <div className="flex w-full justify-center">{process.Name}</div>
                      </div>
                      {/* <div className="flex flex-1 justify-center items-center pb-6">
                        <i className="fa-solid fa-play"></i>
                      </div> */}
                    </div>
                    ))} 
                  </div>
                  <div className="flex flex-1 items-center">
                  <ReactPlayer url="https://www.youtube.com/watch?v=3f96dQgiS0Y"
                    width='100%' height='263px' muted={true} controls={true} playsinline={true} playing={true} loop={true} 
                    config = { { 
                      youtube : { 
                        playerVars : {  showinfo : 0, iv_load_policy : 3  } 
                      } 
                    } }  />
                  </div>
                </div>
                <div className="flex h-14"></div>
                <div className="flex flex-col w-full items-center">
                  {Process_MetalDb.map(process=>(
                  <div className='Contexts flex w-full items-center' key={process.index}>
                    <div className="flex flex-1 flex-col">
                      <span>{process.NameDetail}</span>
                      <p>{process.Detail}</p>
                    </div>
                    <div className="flex w-6"></div>
                    <div className="ContextsIMG flex justify-center items-center">
                      <img className="flex justify-center" src={process.IMG} alt={process.Name}></img>
                    </div>
                  </div>
                  ))} 
                </div>
              </div>
              : null}
            <div className="flex flex-1 flex-col">
            <div className="flex h-10"></div>
            <div className="flex h-20"><a name="heat"></a></div>
              <div className="flex">열처리</div>
              <div className="flex h-10"></div>
              {Process_HeatLoading ? 
              <div className="flex w-full flex-col">
                <div className="flex w-full gap-6">
                  <div className="flex flex-1 grid grid-cols-3 gap-y-6">
                  {Process_HeatDb.map(process=>(
                    <div className='Lists flex flex-1 justify-center items-center' key={process.index}>
                      <div className="flex flex-col items-center">
                        <div className="ListsIMG flex flex-col justify-center items-center">
                          <img className="flex justify-center" src={process.IMG} alt={process.Name}></img>
                        </div>
                        <div className="flex h-3"></div>
                        <div className="flex w-full justify-center">{process.Name}</div>
                      </div>
                      {/* <div className="flex flex-1 justify-center items-center pb-6">
                        <i className="fa-solid fa-play"></i>
                      </div> */}
                    </div>
                    ))} 
                  </div>
                  <div className="flex flex-1 items-center">
                  <ReactPlayer url="https://www.youtube.com/watch?v=001ZGjizru8"
                    width='100%' height='263px' muted={true} controls={true} playsinline={true} playing={true} loop={true} 
                    config = { { 
                      youtube : { 
                        playerVars : {  showinfo : 0, iv_load_policy : 3  } 
                      } 
                    } }  />
                  </div>
                </div>
                <div className="flex h-14"></div>
                <div className="flex flex-col w-full items-center">
                  {Process_HeatDb.map(process=>(
                  <div className='Contexts flex w-full items-center' key={process.index}>
                    <div className="flex flex-1 flex-col">
                      <span>{process.NameDetail}</span>
                      <p>{process.Detail}</p>
                    </div>
                    <div className="flex w-6"></div>
                    <div className="ContextsIMG flex justify-center items-center">
                      <img className="flex justify-center" src={process.IMG} alt={process.Name}></img>
                    </div>
                  </div>
                  ))} 
                </div>
              </div>
              : null}
            </div>
            <div className="flex h-20"></div>            
          </div>
        </div>
        </div>
      </div>

      <div className="Process_MB flex flex-col w-full">
        <div className="ProcessBanner flex flex-col w-full">
          <div className="flex">공정소개</div>
          <p>신아화스텍은 CNC선반 자동화와 냉간 단조 설비의 도입으로 지속적인 고품질 유지 및,<br></br>
             별도의 열처리지점을 운영하여, 조질열처리를 통해 고품질의 제품들을 생산합니다.
          </p>    
        </div>
        <div className="ProcessList flex flex-1 flex-col">
          <div className="flex h-20"></div>
          <div className="flex w-full flex-col">
            <div className="flex">금속가공</div>
            <div className="flex h-8"></div>
            <div className="flex w-full items-center">
              <ReactPlayer url="https://www.youtube.com/watch?v=3f96dQgiS0Y"
              width='100%' height='280px' muted={true} controls={true} playsinline={true} playing={true} loop={true} 
              config = { { 
                youtube : { 
                  playerVars : {  showinfo : 0, iv_load_policy : 3  } 
                } 
              } }  />
            </div>
            <div className="flex h-10"></div>
            <div className="flex w-full items-center">
              {Process_MetalLoading ? 
              <Swiper
              className='List flex flex-1 justify-center' 
              spaceBetween={8}
              slidesPerView={2}
              // navigation
              pagination={{
                type: 'progressbar',
                clickable : false,
              }}
              breakpoints={{
                400 : {
                  slidesPerView : 3
                },
                600 : {
                  slidesPerView : 4
                }
              }}
              // autoplay
              // ={{ delay: 100, disableOnInteraction: false }}
              loop={false}
              loopPreventsSliding={false}
              // onSlideChange={() => { console.log("slide change"); }}
              // onSwiper={(swiper) => { console.log(swiper); }}
              >
                {Process_MetalDb.map(process=>(
                <SwiperSlide className='Lists flex justify-center items-center' key={process.index}>
                  <div className="flex justify-center">
                    <div className="flex flex-col">
                      <div className="ListsIMG flex flex-col justify-center items-center">
                        <img className="flex justify-center" src={process.IMG} alt={process.Name}></img>
                      </div>
                      <div className="flex h-3"></div>
                      <div className="flex justify-center">{process.Name}</div>
                    </div>
                    <div className="flex p-2 justify-center items-center mb-6">
                      <i className="fa-solid fa-play"></i>
                    </div>
                  </div>
                </SwiperSlide>
                ))} 
              </Swiper>
              : null}
            </div>
            <div className="flex h-6"></div>
            <div className="AccordianList flex w-full">
            {Process_MetalLoading ?
              <ul className="flex flex-col w-full">
              {Process_MetalDb.map(process=>(
              <li className='Contexts flex flex-col w-full items-center' key={process.index}>
                <div className="list-top flex w-full">
                  <div className="flex flex-1">{process.NameDetail}</div>
                  <div className="flex"><i ></i></div>
                </div>
                <div className="list-bottom flex flex-col w-full">
                  <p>{process.Detail}</p>
                  <div className="flex h-6"></div>
                  <div className="ContextsIMG flex w-full justify-center">
                    <img className="flex flex-1 justify-center" src={process.IMG} alt={process.Name}></img>
                  </div>
                </div>
              </li>
              ))}
              </ul>
            : null}
            </div>      
          </div>
          <div className="flex h-20"></div>
          <div className="flex w-full flex-col">
            <div className="flex">열처리</div>
            <div className="flex h-8"></div>
            <div className="flex w-full items-center">
              <ReactPlayer url="https://www.youtube.com/watch?v=001ZGjizru8"
              width='100%' height='280px' muted={true} controls={true} playsinline={true} playing={true} loop={true} 
              config = { { 
                youtube : { 
                  playerVars : {  showinfo : 0, iv_load_policy : 3  } 
                } 
              } }  />
            </div>
            <div className="flex h-10"></div>
            <div className="flex w-full items-center">
              {Process_HeatLoading ? 
              <Swiper
              className='List flex flex-1 justify-center' 
              spaceBetween={8}
              slidesPerView={2}
              // navigation
              pagination={{
                type: 'progressbar',
                clickable : false,
              }}
              breakpoints={{
                400 : {
                  slidesPerView : 3
                },
                600 : {
                  slidesPerView : 4
                }
              }}
              // autoplay
              // ={{ delay: 100, disableOnInteraction: false }}
              loop={false}
              loopPreventsSliding={false}
              // onSlideChange={() => { console.log("slide change"); }}
              // onSwiper={(swiper) => { console.log(swiper); }}
              >
                {Process_HeatDb.map(process=>(
                <SwiperSlide className='Lists flex justify-center items-center' key={process.index}>
                  <div className="flex justify-center">
                    <div className="flex flex-col">
                      <div className="ListsIMG flex flex-col justify-center items-center">
                        <img className="flex justify-center" src={process.IMG} alt={process.Name}></img>
                      </div>
                      <div className="flex h-3"></div>
                      <div className="flex justify-center">{process.Name}</div>
                    </div>
                    <div className="flex p-2 justify-center items-center mb-6">
                      <i className="fa-solid fa-play"></i>
                    </div>
                  </div>
                </SwiperSlide>
                ))} 
              </Swiper>
              : null}
            </div>
            <div className="flex h-6"></div>
            <div className="AccordianList flex w-full">
            {Process_HeatLoading ?
              <ul className="flex flex-col w-full">
              {Process_HeatDb.map(process=>(
              <li className='Contexts flex flex-col w-full items-center' key={process.index}>
                <div className="list-top flex w-full">
                  <div className="flex flex-1">{process.NameDetail}</div>
                  <div className="flex"><i ></i></div>
                </div>
                <div className="list-bottom flex flex-col w-full">
                  <p>{process.Detail}</p>
                  <div className="flex h-6"></div>
                  <div className="ContextsIMG flex w-full justify-center">
                    <img className="flex flex-1 justify-center" src={process.IMG} alt={process.Name}></img>
                  </div>
                </div>
              </li>
              ))}
              </ul>
            : null}
            </div>      
          </div>
          <div className="flex h-20"></div>
        </div>
      </div>
    </div>
  );
}

export default Process;
