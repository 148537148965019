import { React, useEffect, useRef, useState } from "react";
import './News.css';
import { Link } from "react-router-dom";
import { getFirestore, collection, getDocs, doc, query, orderBy, limit , where} from "firebase/firestore"; // 데이터 읽어오기
import $ from 'jquery';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";	// 추가

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

SwiperCore.use([Navigation, Pagination, Autoplay])

function News(props) {
  const [NewsDb, SetNewsDb]=useState(null);
  let NewsData=[];
  const [NewsLoading, SetNewsLoading]=useState(false);

  //icons
  const [Iconup1 ,SetIconup1]=useState(true);

  // for paging
  const [NewsPgAll, SetNewsPgAll]=useState(null);
  const NewsFNaviPrev=useRef(null);
  const NewsFNaviNext=useRef(null);
  const NewsFPagination=useRef(null);

  const NewsFNaviPrevMB=useRef(null);
  const NewsFNaviNextMB=useRef(null);
  const [NewsF1st, SetNewsF1st]=useState(1);
  const [NewsFAllP, SetNewsFAllP]=useState(null);

  const paginationNews = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
    el : NewsFPagination.current,
  };

  useEffect(()=>{
    LoadingNewsData();
  },[])

  useEffect(()=>{
    if(NewsDb!==null && NewsDb!==undefined){
      if(NewsDb.length===0){
        NewsFNaviPrev.current.parentElement.parentElement.style.display="none";
        NewsFNaviPrevMB.current.parentElement.parentElement.style.display="none";
      }
      else{
        NewsFNaviPrev.current.parentElement.parentElement.style.display="flex";
        NewsFNaviPrevMB.current.parentElement.parentElement.style.display="flex";
      }
    }
  },[NewsDb])

  async function LoadingNewsData(){
    SetNewsLoading(false);
    const UserListRef = collection(props.db, "News"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot1 = await getDocs(query(UserListRef, orderBy("Date","desc")));
    NewsData.length=0;
    await querySnapshot1.forEach((doc) => {
      NewsData.push(doc.data());
    });
    SetNewsDb(NewsData);
    await PagingNews();
    SetNewsLoading(true);
  }
  
  function PagingNews() {
    let array =[];
    
    for (let i = 0; i <NewsData.length; i+=5) {
      array.push({id:i});
    }
    SetNewsPgAll(array);
    SetNewsFAllP(array.length);
  }

  function MinusNewsPage(params) {
    if(NewsF1st>1){
      SetNewsF1st(NewsF1st-1);
    }
  }

  function PlusNewsPage(params) {
    if(NewsF1st<NewsFAllP){
      SetNewsF1st(NewsF1st+1);
    }
  }

  function ContentsClick(e) {
    if(e.currentTarget.nextElementSibling.classList.contains('active')){
      e.currentTarget.nextElementSibling.classList.remove('active');
      const icon = $(e.target).find('i'); // 클릭된 요소에서 아이콘을 찾습니다.
      icon.removeClass('rotated-icon');
    }
    else{
      e.currentTarget.nextElementSibling.classList.add('active');
      const icon = $(e.target).find('i'); // 클릭된 요소에서 아이콘을 찾습니다.
      icon.addClass('rotated-icon');
    }
  }

  return (
    <div className="News">
      <div className="News_PC flex w-full justify-center">
        <div className="News_PC-News flex flex-col w-full items-center">
          <div className="MenuArea flex items-center">
            <div>News</div>
          </div>
          <div className="NewsList flex flex-col flex-1">
          {NewsLoading && NewsDb.length ?
            <div className="flex w-full flex-col">
              {NewsLoading ? 
              <Swiper
              className='List flex justify-center' 
              // spaceBetween={8}
              slidesPerView={1}
              initialSlide={0}

              navigation= {{ // 좌우 버튼 커스텀 
                prevEl: NewsFNaviPrev.current,
                nextEl: NewsFNaviNext.current
              }}
              touchRatio={0} // 드래기 넘기기 방지
              speed={0}
              pagination={paginationNews}
              // autoplay
              // ={{ delay: 100, disableOnInteraction: false }}
              loop={false}
              loopPreventsSliding={false}
              // onSlideChange={() => { console.log("slide change"); }}
              // onSwiper={(swiper) => { console.log(swiper); }}
              >
                {NewsPgAll.map((arr)=>(
                <SwiperSlide className='Lists flex w-full flex-col' key={arr.index}>
                {NewsDb.filter((News)=> News.id > arr.id && News.id<=(arr.id+5) ).map((News)=>(
                  <div className="flex flex-1 flex-col justify-center items-center" key={News.index}>
                    <div className="HeadLines flex w-full items-center gap-4" onClick={ContentsClick}>
                      <div className="flex w-20 justify-center items-center">{News.Date}</div>
                      <div className="flex w-20"></div>
                      <div className="flex">{News.Title}</div>
                      <i className="fa-solid fa-angle-up"></i>
                    </div>
                    <div className="Contents flex w-full flex-col">
                      {News.Contents.split('<br>').map((line, index) => (
                      <p className='flex w-full flex-wrap' key={index}>{line}</p>
                      ))}
                    </div>
                  </div>
                  ))} 
                </SwiperSlide> 
                ))}
              </Swiper>
              : null}         
            </div>
          : <div className="flex w-full flex-col justify-center items-center">
            <i className="fa-solid fa-exclamation flex"></i>
            <div className="flex">등록된 글이 없습니다.</div>
            </div>}
            <div className="PagingTool flex w-full h-10">
              <div className="flex flex-1"></div>
              <div className="swiper-buttonPrev flex justify-end items-center" >
                <i className="fa-solid fa-chevron-left" ref={NewsFNaviPrev}></i>
              </div>
              <div className="flex flex-1 justify-center items-center" ref={NewsFPagination}></div>
              <div className="swiper-buttonNext flex justify-start items-center" >
                <i className="fa-solid fa-chevron-right" ref={NewsFNaviNext}></i>
              </div>
              <div className="flex flex-1"></div>
            </div>
            <div className="flex h-10"></div>            
            <div className="flex h-20"></div>   
          </div>
        </div>
      </div>

      <div className="News_MB flex flex-col">
        <div className="NewsBanner flex flex-col w-full">
          <div className="MenuArea flex">
            <div className="MenuAreaList flex flex-col">
              <div>News</div>
            </div>
          </div>
        </div>
        <div className="flex h-14"></div>
        <div className="NewsList flex flex-col flex-1">
        {NewsLoading && NewsDb.length ?
          <div className="flex w-full flex-col">
            <Swiper
            className='List flex justify-center' 
            // spaceBetween={8}
            slidesPerView={1}
            initialSlide={0}

            navigation= {{ // 좌우 버튼 커스텀 
              prevEl: NewsFNaviPrevMB.current,
              nextEl: NewsFNaviNextMB.current
            }}
            touchRatio={0} // 드래기 넘기기 방지
            speed={0}
            // autoplay
            // ={{ delay: 100, disableOnInteraction: false }}
            loop={false}
            loopPreventsSliding={false}
            // onSlideChange={() => { console.log("slide change"); }}
            // onSwiper={(swiper) => { console.log(swiper); }}
            >
              {NewsPgAll.map((arr)=>(
              <SwiperSlide className='Lists flex w-full flex-col' key={arr.index}>
              {NewsDb.filter((News)=> News.id > arr.id && News.id<=(arr.id+5) ).map((News)=>(
                <div className="flex flex-1 flex-col justify-center items-center" key={News.index}>
                  <div className="flex w-full">
                    <div className="flex w-20 items-center">{News.Date}</div>
                  </div>
                  <div className="HeadLines flex w-full gap-2" onClick={ContentsClick}>
                    <div className="flex">{News.Title}</div>
                    <div className="flex flex-1 justify-end items-center">
                      <i className="fa-solid fa-angle-up"></i>
                    </div>
                  </div>
                  <div className="Contents flex w-full flex-col">
                    {News.Contents.split('<br>').map((line, index) => (
                    <p className='flex w-full flex-wrap' key={index}>{line}</p>
                    ))}
                  </div>
                </div>
                ))} 
              </SwiperSlide> 
              ))}
            </Swiper>
          </div>
            : <div className="flex w-full flex-col justify-center items-center">
            <i className="fa-solid fa-exclamation flex"></i>
            <div className="flex">등록된 글이 없습니다.</div>
            </div> }
            <div className="PagingTool flex w-full h-10">
              <div className="flex flex-1"></div>
              <div className="swiper-buttonPrev flex justify-end items-center" >
                <i className="fa-solid fa-chevron-left" ref={NewsFNaviPrevMB} onClick={MinusNewsPage}></i>
              </div>
              <div className="flex flex-1 justify-center items-center">
                {NewsF1st} / {NewsFAllP}
              </div>
              <div className="swiper-buttonNext flex justify-start items-center" >
                <i className="fa-solid fa-chevron-right" ref={NewsFNaviNextMB} onClick={PlusNewsPage}></i>
              </div>
              <div className="flex flex-1"></div>
            </div>         
          <div className="flex h-10"></div>            
          <div className="flex h-20"></div>
        </div>
      </div>

    </div>
  );
}

export default News;
