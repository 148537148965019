import { React, useEffect, useRef, useState } from "react";
import './Facility.css';
import { Link } from "react-router-dom";
import { getFirestore, collection, getDocs, doc, query, orderBy, limit , where} from "firebase/firestore"; // 데이터 읽어오기

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";	// 추가

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

SwiperCore.use([Navigation, Pagination, Autoplay])

function Facility(props) {
  const [Facility_MetalDb, SetFacility_MetalDb]=useState(null);
  let Facility_MetalData=[];
  const [Facility_MetalLoading, SetFacility_MetalLoading]=useState(false);

  const [Facility_HeatDb, SetFacility_HeatDb]=useState(null);
  let Facility_HeatData=[];
  const [Facility_HeatLoading, SetFacility_HeatLoading]=useState(false);

  // for paging
  const [Facility_MetalPgAll, SetFacility_MetalPgAll]=useState(null);
  const MetalFNaviPrev=useRef(null);
  const MetalFNaviNext=useRef(null);
  const MetalFPagination=useRef(null);

  const MetalFNaviPrevMB=useRef(null);
  const MetalFNaviNextMB=useRef(null);
  const [MetalF1st, SetMetalF1st]=useState(1);
  const [MetalFAllP, SetMetalFAllP]=useState(null);

  const [PaginationBranchF, SetPaginationBranchF]=useState(35);

  const [Facility_HeatPgAll, SetFacility_HeatPgAll]=useState(null);
  const HeatFNaviPrev=useRef(null);
  const HeatFNaviNext=useRef(null);
  const HeatFPagination=useRef(null);

  const HeatFNaviPrevMB=useRef(null);
  const HeatFNaviNextMB=useRef(null);
  const [HeatF1st, SetHeatF1st]=useState(1);
  const [HeatFAllP, SetHeatFAllP]=useState(null);

  const paginationMetal = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
    el : MetalFPagination.current,
  };

  const paginationHeat = {
    clickable: true,

    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
    el : HeatFPagination.current,
  };

  useEffect(()=>{
    LoadingFacilityData_Metal();
  },[])

  useEffect(()=>{
    LoadingFacilityData_Heat();       
  },[PaginationBranchF])

  async function LoadingFacilityData_Metal(){
    SetFacility_MetalLoading(false);
    const UserListRef = collection(props.db, "Facility"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot1 = await getDocs(query(UserListRef, where("id", ">=", 0), where("Spot", "==", "본사")));
    Facility_MetalData.length=0;
    await querySnapshot1.forEach((doc) => {
      Facility_MetalData.push(doc.data());
    });
    SetFacility_MetalDb(Facility_MetalData);
    SetPaginationBranchF(Facility_MetalData.length);
    await PagingMetal();
    SetFacility_MetalLoading(true);
  }

  async function LoadingFacilityData_Heat(){
    SetFacility_HeatLoading(false);
    const UserListRef = collection(props.db, "Facility"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, where("id", ">=", 0), where("Spot", "==", "열처리")));
    Facility_HeatData.length=0;
    await querySnapshot.forEach((doc) => {
      Facility_HeatData.push(doc.data());
    });
    SetFacility_HeatDb(Facility_HeatData);
    await PagingHeat();
    SetFacility_HeatLoading(true);
  }
  
  function PagingMetal() {
    let array =[];
    
    for (let i = 0; i <Facility_MetalData.length; i+=5) {
      array.push({id:i});
    }
    SetFacility_MetalPgAll(array);
    SetMetalFAllP(array.length);
  }

  function PagingHeat() {
    let array =[];
    
    for (let i = PaginationBranchF; i <Facility_HeatData.length+PaginationBranchF+1; i+=5) {
      array.push({id:i});
    }
    SetFacility_HeatPgAll(array);
    SetHeatFAllP(array.length);
  }

  function MinusMetalPage(params) {
    if(MetalF1st>1){
      SetMetalF1st(MetalF1st-1);
    }
  }

  function PlusMetalPage(params) {
    if(MetalF1st<MetalFAllP){
      SetMetalF1st(MetalF1st+1);
    }
  }

  function MinusHeatPage(params) {
    if(HeatF1st>1){
      SetHeatF1st(HeatF1st-1);
    }
  }

  function PlusHeatPage(params) {
    if(HeatF1st<HeatFAllP){
      SetHeatF1st(HeatF1st+1);
    }
  }

  return (
    <div className="Facility">
      <div className="Facility_PC flex w-full justify-center">
        <div className="Facility_PC-Facility flex con">
          <div className="MenuArea flex">
            <div className="MenuAreaList flex flex-col">
              <div>설비현황</div>
            </div>
          </div>
          <div className="Facilitys flex flex-col">
            <div className="FacilityList flex flex-col">
              <div className="flex w-full flex-col">
                <div className="flex">본사</div>
                <div className="flex h-10"></div>
                <div className="ListIMG flex w-full gap-4">
                  <img className="flex flex-1" src="https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/Facility%2F%EC%83%9D%EC%82%B0%EC%84%A4%EB%B9%841%20(2).jpg?alt=media&token=b74c6863-e739-4f6b-a5f7-1d09e540b81a" alt="설비현황1"></img>
                  <img className="flex flex-1" src="https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/Facility%2F%EC%83%9D%EC%82%B0%EC%84%A4%EB%B9%842%20(2).jpg?alt=media&token=0b1e0278-9688-41f9-b73a-00f80cb677dd" alt="설비현황"></img>
                </div>
                <div className="flex h-10"></div>
                <div className="List-Top flex w-full">
                  <div className="SwiperLists flex w-10 justify-center items-center">No</div>
                  <div className="SwiperLists flex flex-1 justify-center items-center">설비명</div>
                  <div className="SwiperLists flex w-20 justify-center items-center">설비종류</div>
                  <div className="SwiperLists flex flex-1 justify-center items-center">설비표준</div>
                  <div className="SwiperLists flex w-20 justify-center items-center">보유 대수</div>
                </div>
                {Facility_MetalLoading ?
                <Swiper
                className='List flex justify-center' 
                // spaceBetween={8}
                slidesPerView={1}
                initialSlide={0}

                navigation= {{ // 좌우 버튼 커스텀 
                  prevEl: MetalFNaviPrev.current,
                  nextEl: MetalFNaviNext.current
                }}
                touchRatio={0} // 드래기 넘기기 방지
                speed={0}
                pagination={paginationMetal}
                // autoplay
                // ={{ delay: 100, disableOnInteraction: false }}
                loop={false}
                loopPreventsSliding={false}
                // onSlideChange={() => { console.log("slide change"); }}
                // onSwiper={(swiper) => { console.log(swiper); }}
                >
                  {Facility_MetalPgAll.map((arr)=>(
                  <SwiperSlide className='Lists flex w-full flex-col' key={arr.index}>
                  {Facility_MetalDb.filter((facility)=> facility.id > arr.id && facility.id<=(arr.id+5) ).map((facility)=>(
                    <div className="flex flex-1 justify-center items-center" key={facility.index}>
                      <div className="flex w-10 justify-center items-center">{facility.No}</div>
                      <div className="flex flex-1 justify-center items-center">{facility.Name}</div>
                      <div className="flex w-20 justify-center items-center">{facility.Type}</div>
                      <div className="flex flex-1 justify-center items-center">{facility.Standard}</div>
                      <div className="flex w-20 justify-center items-center">{facility.Quantity}</div>
                    </div>
                    ))} 
                  </SwiperSlide> 
                  ))}
                  
                </Swiper>
                : null}
                <div className="PagingTool flex w-full h-10">
                    <div className="flex flex-1"></div>
                    <div className="swiper-buttonPrev flex justify-end items-center" >
                      <i className="fa-solid fa-chevron-left" ref={MetalFNaviPrev}></i>
                    </div>
                    <div className="flex flex-1 justify-center items-center" ref={MetalFPagination}></div>
                    <div className="swiper-buttonNext flex justify-start items-center" >
                      <i className="fa-solid fa-chevron-right" ref={MetalFNaviNext}></i>
                    </div>
                    <div className="flex flex-1"></div>
                </div>
                <div className="flex h-10"></div>            
                <div className="flex h-20"></div>            
              </div>
            </div>
            <div className="FacilityList flex flex-col">
              <div className="flex w-full flex-col">
                <div className="flex">열처리</div>
                <div className="flex h-10"></div>
                <div className="ListIMG flex w-full gap-4">
                  <img className="flex flex-1" src="https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/Facility%2F%EC%97%B4%EC%B2%98%EB%A6%AC%20%EC%84%A4%EB%B9%84%ED%98%84%ED%99%A91.jpg?alt=media&token=b4412e51-96bb-4efd-adf9-ff29f9c74655" alt="설비현황1"></img>
                  <img className="flex flex-1" src="https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/Facility%2F%EC%97%B4%EC%B2%98%EB%A6%AC%20%EC%84%A4%EB%B9%84%ED%98%84%ED%99%A9%202.jpg?alt=media&token=f89e6d66-f940-4e2a-b7cf-81d32a719be2" alt="설비현황"></img>
                </div>
                <div className="flex h-10"></div>
                <div className="List-Top flex w-full">
                  <div className="SwiperLists flex w-10 justify-center items-center">No</div>
                  <div className="SwiperLists flex flex-1 justify-center items-center">설비명</div>
                  <div className="SwiperLists flex w-20 justify-center items-center">설비종류</div>
                  <div className="SwiperLists flex w-1/5 justify-center items-center">설비표준</div>
                  <div className="SwiperLists flex w-1/5 justify-center items-center">관리번호</div>
                  <div className="SwiperLists flex w-20 justify-center items-center">제작처</div>
                </div>
                {Facility_HeatLoading ?
                <Swiper
                className='List flex justify-center' 
                // spaceBetween={8}
                slidesPerView={1}
                initialSlide={0}

                navigation= {{ // 좌우 버튼 커스텀 
                  prevEl: HeatFNaviPrev.current,
                  nextEl: HeatFNaviNext.current
                }}
                touchRatio={0} // 드래기 넘기기 방지
                speed={0}
                pagination={paginationHeat}
                // autoplay
                // ={{ delay: 100, disableOnInteraction: false }}
                loop={false}
                loopPreventsSliding={false}
                // onSlideChange={() => { console.log("slide change"); }}
                // onSwiper={(swiper) => { console.log(swiper); }}
                >
                  {Facility_HeatPgAll.map((arr)=>(
                  <SwiperSlide className='Lists flex w-full flex-col' key={arr.index}>
                  {Facility_HeatDb.filter((facility)=> facility.id > arr.id && facility.id<=(arr.id+5) ).map((facility)=>(
                    <div className="flex flex-1 justify-center items-center" key={facility.index}>
                      <div className="flex w-10 justify-center items-center">{facility.No}</div>
                      <div className="flex flex-1 justify-center items-center">{facility.Name}</div>
                      <div className="flex w-20 justify-center items-center">{facility.Type}</div>
                      <div className="flex w-1/5 justify-center items-center">{facility.Standard}</div>
                      <div className="flex w-1/5 justify-center items-center">{facility.관리번호}</div>
                      <div className="flex w-20 justify-center items-center">{facility.제작처}</div>
                    </div>
                    ))} 
                  </SwiperSlide> 
                  ))}
                  
                </Swiper>
                : null}
                <div className="PagingTool flex w-full h-10">
                    <div className="flex flex-1"></div>
                    <div className="swiper-buttonPrev flex justify-end items-center" >
                      <i className="fa-solid fa-chevron-left" ref={HeatFNaviPrev}></i>
                    </div>
                    <div className="flex flex-1 justify-center items-center" ref={HeatFPagination}></div>
                    <div className="swiper-buttonNext flex justify-start items-center" >
                      <i className="fa-solid fa-chevron-right" ref={HeatFNaviNext}></i>
                    </div>
                    <div className="flex flex-1"></div>
                </div>
                <div className="flex h-20"></div>            
                <div className="flex h-10"></div>            
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Facility_MB flex flex-col">
        <div className="FacilityBanner flex flex-col w-full">
          <div className="MenuArea flex">
            <div className="MenuAreaList flex flex-col">
              <div>설비현황</div>
            </div>
          </div>
        </div>
        <div className="flex h-14"></div>
        <div className="FacilityList flex flex-col">
          <div className="flex w-full flex-col">
            <div className="flex">본사</div>
            <div className="flex h-5"></div>
            <div className="ListIMG flex flex-col w-full">
              <img className="flex" src="https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/Facility%2F%EC%83%9D%EC%82%B0%EC%84%A4%EB%B9%841%20(2).jpg?alt=media&token=b74c6863-e739-4f6b-a5f7-1d09e540b81a" alt="설비현황1"></img>
              <img className="flex" src="https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/Facility%2F%EC%83%9D%EC%82%B0%EC%84%A4%EB%B9%842%20(2).jpg?alt=media&token=0b1e0278-9688-41f9-b73a-00f80cb677dd" alt="설비현황"></img>
            </div>
            <div className="flex h-2"></div>
            {Facility_MetalLoading ?
            <Swiper
            className='List flex justify-center' 
            // spaceBetween={8}
            slidesPerView={1}
            initialSlide={0}

            navigation= {{ // 좌우 버튼 커스텀 
              prevEl: MetalFNaviPrevMB.current,
              nextEl: MetalFNaviNextMB.current
            }}
            touchRatio={0} // 드래기 넘기기 방지
            speed={0}
            // autoplay
            // ={{ delay: 100, disableOnInteraction: false }}
            loop={false}
            loopPreventsSliding={false}
            // onSlideChange={() => { console.log("slide change"); }}
            // onSwiper={(swiper) => { console.log(swiper); }}
            >
              {Facility_MetalPgAll.map((arr)=>(
              <SwiperSlide className='Lists flex w-full flex-col' key={arr.index}>
              <div className="List-Top flex">
                <div className="flex w-8 justify-center items-center">No</div>
                <div className="flex flex-1 items-center">설비명</div>
                <div className="flex w-16 items-center">설비종류</div>
                <div className="flex w-16 items-center">설비표준</div>
                <div className="flex w-12 justify-center items-center">보유대수</div>
              </div>
              {Facility_MetalDb.filter((facility)=> facility.id > arr.id && facility.id<=(arr.id+5) ).map((facility)=>(
                <div className="List-Bottom flex flex-1 items-center" key={facility.index}>
                  <div className="flex w-8 justify-center items-center">{facility.No}</div>
                  <div className="flex flex-1 items-center">{facility.Name}</div>
                  <div className="flex w-16 items-center">{facility.Type}</div>
                  <div className="flex w-16 items-center">{facility.Standard}</div>
                  <div className="flex w-12 justify-center items-center">{facility.Quantity}</div>
                </div>
                ))} 
              </SwiperSlide> 
              ))}
            </Swiper>
            : null}
            <div className="PagingTool flex w-full h-10">
                <div className="flex flex-1"></div>
                <div className="swiper-buttonPrev flex justify-end items-center" >
                  <i className="fa-solid fa-chevron-left" ref={MetalFNaviPrevMB} onClick={MinusMetalPage}></i>
                </div>
                <div className="flex flex-1 justify-center items-center">
                  {MetalF1st} / {MetalFAllP}
                </div>
                <div className="swiper-buttonNext flex justify-start items-center" >
                  <i className="fa-solid fa-chevron-right" ref={MetalFNaviNextMB} onClick={PlusMetalPage}></i>
                </div>
                <div className="flex flex-1"></div>
            </div>         
            <div className="flex h-20"></div>            
          </div>
        </div>
        <div className="FacilityList flex flex-col">
          <div className="flex w-full flex-col">
            <div className="flex">열처리</div>
            <div className="flex h-5"></div>
            <div className="ListIMG flex w-full flex-col">
              <img className="flex" src="https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/Facility%2F%EC%97%B4%EC%B2%98%EB%A6%AC%20%EC%84%A4%EB%B9%84%ED%98%84%ED%99%A91.jpg?alt=media&token=b4412e51-96bb-4efd-adf9-ff29f9c74655" alt="설비현황1"></img>
              <img className="flex" src="https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/Facility%2F%EC%97%B4%EC%B2%98%EB%A6%AC%20%EC%84%A4%EB%B9%84%ED%98%84%ED%99%A9%202.jpg?alt=media&token=f89e6d66-f940-4e2a-b7cf-81d32a719be2" alt="설비현황"></img>
            </div>
            <div className="flex h-2"></div>
            {Facility_HeatLoading ?
            <Swiper
            className='List flex justify-center' 
            // spaceBetween={8}
            slidesPerView={1}
            initialSlide={0}

            navigation= {{ // 좌우 버튼 커스텀 
              prevEl: HeatFNaviPrevMB.current,
              nextEl: HeatFNaviNextMB.current
            }}
            touchRatio={0} // 드래기 넘기기 방지
            speed={0}
            // autoplay
            // ={{ delay: 100, disableOnInteraction: false }}
            loop={false}
            loopPreventsSliding={false}
            // onSlideChange={() => { console.log("slide change"); }}
            // onSwiper={(swiper) => { console.log(swiper); }}
            >
              {Facility_HeatPgAll.map((arr)=>(
              <SwiperSlide className='Lists flex w-full flex-col' key={arr.index}>
              <div className="List-Top flex">
                <div className="flex w-8 justify-center items-center">No</div>
                <div className="flex flex-1 items-center">설비명</div>
                <div className="flex w-16 justify-center items-center">설비종류</div>
                <div className="flex w-16 items-center">설비표준</div>
                <div className="flex flex-1 items-center">관리번호</div>
                <div className="flex w-16 items-center">제작처</div>
              </div>
              {Facility_HeatDb.filter((facility)=> facility.id > arr.id && facility.id<=(arr.id+5) ).map((facility)=>(
                <div className="List-Bottom flex flex-1 items-center" key={facility.index}>
                  <div className="flex w-8 justify-center items-center">{facility.No}</div>
                  <div className="flex flex-1 items-center">{facility.Name}</div>
                  <div className="flex w-16 justify-center items-center">{facility.Type}</div>
                  <div className="flex w-16 items-center">{facility.Standard}</div>
                  <div className="flex flex-1 items-center">{facility.관리번호}</div>
                  <div className="flex w-16 items-center">{facility.제작처}</div>
                </div>
                ))} 
              </SwiperSlide> 
              ))}
            </Swiper>
            : null}
            <div className="PagingTool flex w-full h-10">
                <div className="flex flex-1"></div>
                <div className="swiper-buttonPrev flex justify-end items-center" >
                  <i className="fa-solid fa-chevron-left" ref={HeatFNaviPrevMB} onClick={MinusHeatPage}></i>
                </div>
                <div className="flex flex-1 justify-center items-center">
                  {HeatF1st} / {HeatFAllP}
                </div>
                <div className="swiper-buttonNext flex justify-start items-center" >
                  <i className="fa-solid fa-chevron-right" ref={HeatFNaviNextMB} onClick={PlusHeatPage}></i>
                </div>
                <div className="flex flex-1"></div>
            </div>
            <div className="flex h-20"></div>            
            <div className="flex h-10"></div>            
          </div>
        </div>
          
      </div>

    </div>
  );
}

export default Facility;
