import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import './Footer.css';
import $ from 'jquery';

function Footer() {

  const [FMSSlide, SetFMSSlide] = useState(false);

  function linkClicked(){
    $(window).scrollTop(0);        
    $(".Menu").stop().slideDown(0);
  }

  useEffect(() => {
    $('.FMSite ul').slideUp(0);
  }, []);

  function FMSSlideTrigger() {
    if(FMSSlide){
      $('.FMSite ul').stop().slideUp(500); 
      SetFMSSlide(false);
    }
    else{
      $('.FMSite ul').stop().slideDown(500); 
      SetFMSSlide(true);
    }
  }

  return (
    <div className="Footer">
      <div className="Footer_PC flex flex-col w-full items-center">
        <div className="Footer_PC-Top flex">
          <div className="FPT-Logo flex">
            <Link to="" className="flex items-center" onClick={linkClicked}><img src="./img/FooterLogo.png" alt="푸터로고" /></Link>
          </div>
          <div className="flex flex-1"></div>
          <div className="FPT-Upper flex" onClick={linkClicked}>Top</div>
        </div>
        <div className="Footer_PC-Menu flex">
          <ul className="FPT-Menu flex">
            <li className="flex flex-1 justify-center items-center">
              <Link to="/AboutUs" onClick={linkClicked}>회사소개</Link>
            </li>
            <li className="flex flex-1 justify-center items-center">
              <Link to="/Product" onClick={linkClicked}>생산</Link>
            </li>
            <li className="flex flex-1 justify-center items-center">
              <Link to="/Research" onClick={linkClicked}>R&D</Link>
            </li>
            <li className="flex flex-1 justify-center items-center">
              <Link to="/News" onClick={linkClicked}>고객지원</Link>
            </li>
          </ul>
          <div className="flex flex-1"></div>
          <div className="FMSite flex w-full justify-end items-center" onClick={FMSSlideTrigger}>
            <div>Family Site</div>
            {FMSSlide ? 
            <i className="fa-solid fa-chevron-down ml-3"></i>
            : <i className="fa-solid fa-chevron-up ml-3"></i>}
            <ul>
              <li><a href="https://www.sinksense.co.kr/" target="_blank">싱크센스 공식몰</a></li>
            </ul>
          </div>
        </div>
        <div className="FPT-CopyRight flex">
          COPYRIGHT(C) SHIN-A FASTEC CO.,LTD
        </div>
      </div>

      <div className="Footer_MB flex flex-col w-full items-center">
        <div className="Footer_MB-Top flex">
          <div className="FPT-Logo flex">
            <Link to="" className="flex items-center" onClick={linkClicked}><img src="./img/FooterLogo.png" alt="푸터로고" /></Link>
          </div>
          <div className="flex flex-1"></div>
          <div className="FPT-Upper flex" onClick={linkClicked}>Top</div>
        </div>
        <div className="Footer_MB-Menu flex flex-col">
          <ul className="FPT-Menu flex">
            <li className="flex flex-1 justify-center items-center">
              <Link to="/AboutUs" onClick={linkClicked}>회사소개</Link>
            </li>
            <li className="flex flex-1 justify-center items-center">
              <Link to="/Product" onClick={linkClicked}>생산</Link>
            </li>
            <li className="flex flex-1 justify-center items-center">
              <Link to="/Research" onClick={linkClicked}>R&D</Link>
            </li>
            <li className="flex flex-1 justify-center items-center">
              <Link to="/News" onClick={linkClicked}>고객지원</Link>
            </li>
          </ul>
          <div className="flex h-8"></div>
          <div className="FMSite flex w-full justify-end items-center" onClick={FMSSlideTrigger}>
            <div>Family Site</div>
            {FMSSlide ? 
            <i className="fa-solid fa-chevron-down ml-3"></i>
            : <i className="fa-solid fa-chevron-up ml-3"></i>}
            <ul>
              <li><a href="https://www.sinksense.co.kr/" target="_blank">싱크센스 공식몰</a></li>
            </ul>
          </div>
        </div>
        <div className="FPT-CopyRight flex">
          COPYRIGHT(C) SHIN-A FASTEC CO.,LTD
        </div>
      </div>
    </div>
  );
}

export default Footer;
