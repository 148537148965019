import { React, useEffect, useRef, useState } from "react";
import './AboutUs.css';
import $ from 'jquery';

import { Link } from "react-router-dom";
import { getFirestore, collection, getDocs, doc, query, orderBy, limit , where} from "firebase/firestore"; // 데이터 읽어오기

function AboutUs(props) {
  const [CI_ProductDb, SetCI_ProductDb]=useState(null);
  let CI_ProductData=[];
  const [CI_ProductLoading, SetCI_ProductLoading]=useState(false);

  const [HistoryDb, SetHistoryDb]=useState(null);
  let HistoryData=[];
  const [HistoryLoading, SetHistoryLoading]=useState(false);

  const [HistoryYearPC, SetHistoryYearPC]=useState(2023);
  const HistoryYearTopPC=useRef(null);
  const HistoryMonthTopPC=useRef(null);

  const [HistoryYearMB, SetHistoryYearMB]=useState(2023);
  const HistoryYearTopMB=useRef(null);
  const HistoryMonthTopMB=useRef(null);

  const [CustomerDb, SetCustomerDb]=useState(null);
  let CustomerData=[];
  const [CustomerLoading, SetCustomerLoading]=useState(false);

  const [scrollY, SetscrollY]=useState(null);

  useEffect(()=>{
    $(".AboutUs_PC-sub-menu").stop().slideUp(0);
    $(".AboutUs_MB-sub-menu").stop().slideUp(0);

    LoadingCI_ProductData();
    LoadingHistoryData();
    LoadingCustomerData();
  },[])

  useEffect(()=>{
    if (HistoryLoading===true && HistoryMonthTopPC!==null && HistoryYearTopPC!==null) {
      ChangeYearPC();
    }

    if (HistoryLoading===true && HistoryMonthTopMB!==null && HistoryYearTopMB!==null) {
      ChangeYearMB();
    }
  },[scrollY])

  window.addEventListener('scroll', (e) => {
    SetscrollY(window.scrollY);
  })

  async function LoadingCI_ProductData(){
    SetCI_ProductLoading(false);
    const UserListRef = collection(props.db, "CI_Product"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, orderBy("No", "asc")));
    CI_ProductData.length=0;
    await querySnapshot.forEach((doc) => {
      CI_ProductData.push(doc.data());
    });
    SetCI_ProductDb(CI_ProductData);
    SetCI_ProductLoading(true);
  }

  async function LoadingHistoryData(){
    SetHistoryLoading(false);
    const UserListRef = collection(props.db, "History"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, orderBy("No", "asc")));
    HistoryData.length=0;
    await querySnapshot.forEach((doc) => {
      HistoryData.push(doc.data());
    });
    SetHistoryDb(HistoryData);
    SetHistoryLoading(true);
  }

  async function LoadingCustomerData(){
    SetCustomerLoading(false);
    const UserListRef = collection(props.db, "Customer"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, orderBy("No", "asc")));
    CustomerData.length=0;
    await querySnapshot.forEach((doc) => {
      CustomerData.push(doc.data());
    });
    SetCustomerDb(CustomerData);
    SetCustomerLoading(true);
  }

  function ChangeYearPC() {
    const historyYear=HistoryYearTopPC.current.offsetTop;
    const array=HistoryMonthTopPC.current.childNodes;
    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      if (element.classList.contains('Sticky')) {
        element.classList.remove('Sticky');
      }
      if(historyYear>=element.offsetTop && historyYear<=(element.offsetTop+element.offsetHeight)){
        const decadeElement=parseInt(element.id/10)*10;
        SetHistoryYearPC(decadeElement);
      }
    }
    ChangeYearColorPC();
  }

  function ChangeYearColorPC() {
    const a = document.getElementsByName(`${HistoryYearPC}`);
    for (let i = 0; i < a.length; i++) {
      const element = a[i];
      element.classList.add('Sticky'); 
    }
  }

  function ChangeYearMB() {
    const historyYear=HistoryYearTopMB.current.offsetTop;
    const array=HistoryMonthTopMB.current.childNodes;
    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      if (element.classList.contains('Sticky')) {
        element.classList.remove('Sticky');
      }
      if(historyYear>=element.offsetTop && historyYear<=(element.offsetTop+element.offsetHeight)){
        const decadeElement=parseInt(element.id/10)*10;
        SetHistoryYearMB(decadeElement);
      }
    }
    ChangeYearColorMB();
  }

  function ChangeYearColorMB() {
    const a = document.getElementsByName(`${HistoryYearMB}`);
    for (let i = 0; i < a.length; i++) {
      const element = a[i];
      element.classList.add('Sticky'); 
    }
  }

  $(window).on('mousewheel',function(e){
    var wheel = e.originalEvent.wheelDelta;
    if(wheel>0){
      if(window.scrollY > 480){
        $(".AboutUs_PC-sub-menu").stop().slideDown(500);
      }
      else{
        $(".AboutUs_PC-sub-menu").stop().slideUp(0);
      }

      if(window.scrollY > 380){
        $(".AboutUs_MB-sub-menu").stop().slideDown(500);
      }
      else{
        $(".AboutUs_MB-sub-menu").stop().slideUp(0);
      }
    } 
    else {
      $(".AboutUs_PC-sub-menu").stop().slideUp(500);
      $(".AboutUs_MB-sub-menu").stop().slideUp(500);
    }     
  });

  return (
    <div className="AboutUs">
      <div className="AboutUs_PC flex flex-col w-full items-center">
       <div className="AboutUs_PC-sub-menu flex w-full justify-center">
          <ul className="flex">
            <li className="flex h-full flex-1"></li>
            <li className="flex h-full justify-center items-center">
              <a href="#ci">CI</a>
            </li>
            <li className="flex h-full justify-center items-center">
              <a href="#history">회사연혁</a>
            </li>
            <li className="flex h-full justify-center items-center">
              <a href="#group">조직도</a>
            </li>
            <li className="flex h-full justify-center items-center">
              <a href="#customer">주요거래처</a>
            </li>
          </ul>
        </div>
        <div className="AboutUs_PC-banner flex flex-col w-full items-center">
          <div className="flex con w-full h-full flex-col justify-end">
            <div className="AboutUs_PC-banner-top flex flex-col w-full justify-end">
              <div>COMPANY<br></br>INTRODUCTION</div>
              <div className="text-2xl pl-2">회사소개</div>
            </div>
            <div className="flex h-14"></div>
            <div className="AboutUs_PC-banner-menu flex w-full justify-end">
              <ul className="flex"><a name="ci"></a>
              <li className="flex flex-1 h-full justify-center">
                  <a href="#ci">CI</a>
                </li>
                <li className="flex flex-1 h-full justify-center">
                  <a href="#history">회사연혁</a>
                </li>
                <li className="flex flex-1 h-full justify-center">
                  <a href="#group">조직도</a>
                </li>
                <li className="flex flex-1 h-full justify-center">
                  <a href="#customer">주요거래처</a>
                </li>
              </ul>
            </div>
            <div className="flex h-6"></div>
          </div>
        </div>
        <div className="AboutUs_PC-CI con flex w-full flex-col">
          <div className="flex h-24"></div>
          <div className="flex w-full">CI</div>
          <div className="flex h-10"></div>
          <div className="flex w-full">
            <img src="https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/AboutUs%2F%ED%9A%8C%EC%82%AC%EC%86%8C%EA%B0%9C-CI%EB%B0%B0%EB%84%88.png?alt=media&token=0a3fbc80-82ac-43e8-af02-133bbb70cbbf" alt="회사배너"></img>
          </div>
          <div className="flex h-14"></div>
          {CI_ProductLoading ?
            <div className="flex w-full justify-between">
            {CI_ProductDb.map(ci_product=>(
              <div className="AboutUs_PC-CI-lists flex flex-col items-center" key={ci_product.index}>
                <img className="flex" src={ci_product.IMG} alt={ci_product.Name}></img>
                <div className="flex w-full mb-1">{ci_product.Name}</div>
                <div className="flex w-full">{ci_product.Detail}</div>
              </div>
            ))}
            </div>
          : null}
        </div>
        <div className="AboutUs_PC-History flex w-full flex-col items-center">
        <div className="flex h-36"><a name="history"></a></div>
          <div className="flex con w-full">회사연혁</div>
          <div className="flex h-10"></div>
          <div className="Frame flex w-full">
            <div className="Years flex flex-1 flex-col items-end" >
              <div ref={HistoryYearTopPC}>~ {HistoryYearPC}</div>
            </div>
            <div className="flex w-4"></div>
            {HistoryLoading ? 
            <div className="Month flex w-3/5 flex-col" ref={HistoryMonthTopPC}>
              <div className="flex h-5"></div>
              {HistoryDb.map(history=>(
                <div className="Months flex h-full w-full" key={history.index} id={history.Year} name={history.Decade} >
                  <div className="flex justify-center items-center">{history.Year}.{history.Month}</div>
                  <div className="flex w-5"></div>
                  <div className="flex flex-1 items-center">{history.Detail}</div>
                </div>
              ))}
            </div>
            : null}
          </div>
        </div>
        <div className="AboutUs_PC-Ogz con flex flex-col w-full">
          <div className="flex h-36"><a name="group"></a></div>
          <div className="flex w-full">조직도</div>
          <div className="flex h-10"></div>
          <div className="Pictures1 flex flex-col items-center">
            <img src="https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/AboutUs%2F%EC%A1%B0%EC%A7%81%EB%8F%84%20color%20red-01.png?alt=media&token=efcf6912-84a5-4e55-9840-ee0d0326806a" alt="조직도1"></img>
            <div className="flex h-4"></div>
            <div className="Pictures2 flex flex-1 justify-center">
              <div className="flex flex-1 justify-end">
                <img src="https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/AboutUs%2F%EC%A1%B0%EC%A7%81%EB%8F%84%20color%20red-02.png?alt=media&token=53810c68-887b-4ddf-ba03-1037c495c218" alt="조직도2"></img>
              </div>
              <div className="flex h-8"></div>
              <div className="flex flex-1">
                <img src="https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/AboutUs%2F%EC%A1%B0%EC%A7%81%EB%8F%84%20color%20red-03.png?alt=media&token=6deea132-2ac0-4c50-901b-8606e3815808" alt="조직도3"></img>
              </div>
            </div>
          </div>
          
        </div>
        <div className="AboutUs_PC-Customer con flex flex-col w-full">
          <div className="flex h-36"><a name="customer"></a></div>
          <div className="flex w-full">주요거래처</div>
          <div className="flex h-10"></div>
          <div className="flex w-full justify-center">
          {CustomerLoading ?
            <div className="grid grid-cols-4 gap-4">
            {CustomerDb.map(customer=>(
              <div className="AboutUs_PC-Customer-lists flex flex-col items-center mb-2" key={customer.index}>
                <div className="flex w-full justify-center items-center">
                  <img src={customer.IMG} alt={customer.Name}></img>
                </div>
                <div className="flex w-full justify-center mt-2 mb-1">{customer.Name}</div>
              </div>
            ))}
            </div>
          : null}
          </div>
        </div>
        <div className="flex h-10"></div>
        <div className="flex h-20"></div>

      </div>

      <div className="AboutUs_MB flex flex-col w-full items-center">
        <div className="AboutUs_MB-sub-menu flex w-full justify-center">
          <ul className="flex w-full">
            <li className="flex flex-1 h-full justify-center items-center">
              <a href="#cimb">CI</a>
            </li>
            <li className="flex flex-1 h-full justify-center items-center">
              <a href="#historymb">회사연혁</a>
            </li>
            <li className="flex flex-1 h-full justify-center items-center">
              <a href="#groupmb">조직도</a>
            </li>
            <li className="flex flex-1 h-full justify-center items-center">
              <a href="#customermb">주요거래처</a>
            </li>
          </ul>
        </div>
        <div className="AboutUs_MB-banner flex flex-col w-full">
          <div className="AboutUs_MB-banner-top flex flex-col flex-1 justify-end">
            <div>COMPANY<br></br>INTRODUCTION</div>
            <div className="text-2xl pl-2">회사소개</div>
          </div>
          <div className="flex h-10"><a name="cimb"></a></div>
          <div className="AboutUs_MB-banner-menu flex w-full justify-end">
            <ul className="flex w-full">
              <li className="flex flex-1 h-full justify-center">
                <a href="#cimb">CI</a>
              </li>
              <li className="flex flex-1 h-full justify-center">
                <a href="#historymb">회사연혁</a>
              </li>
              <li className="flex flex-1 h-full justify-center">
                <a href="#groupmb">조직도</a>
              </li>
              <li className="flex flex-1 h-full justify-center">
                <a href="#customermb">주요거래처</a>
              </li>
            </ul>
          </div>
          <div className="flex h-6"></div>
        </div>
        <div className="AboutUs_MB-CI flex w-full flex-col">
          <div className="flex h-16"></div>
          <div className="flex w-full">CI</div>
          <div className="flex h-6"></div>
          <div className="flex w-full">
            <img src="https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/AboutUs%2F%ED%9A%8C%EC%82%AC%EC%86%8C%EA%B0%9C-CI%EB%B0%B0%EB%84%88.png?alt=media&token=0a3fbc80-82ac-43e8-af02-133bbb70cbbf" alt="회사배너"></img>
          </div>
          <div className="flex h-6"></div>
          {CI_ProductLoading ?
            <div className="flex flex-col w-full items-center">
            {CI_ProductDb.map(ci_product=>(
              <div className="AboutUs_MB-CI-lists flex flex-col w-full" key={ci_product.index}>
                <div className="flex w-full mb-1">{ci_product.Name}</div>
                <div className="flex w-full">
                  <p className="flex flex-1 pr-2">{ci_product.Detail}</p>
                  <div className="ListsIMG flex">
                    <img className="flex" src={ci_product.IMG} alt={ci_product.Name}></img>
                  </div>
                </div>
              </div>
            ))}
            </div>
          : null}
        </div>
        <div className="AboutUs_MB-History flex w-full flex-col items-center">
        <div className="flex h-36"><a name="historymb"></a></div>
          <div className="flex w-full">회사연혁</div>
          <div className="flex h-8"></div>
          <div className="Frame flex w-full">
            <div className="Years flex w-1/4 flex-col items-end" >
              <div className="flex h-4"></div>
              <div ref={HistoryYearTopMB}>~ {HistoryYearMB}</div>
            </div>
            <div className="flex w-4"></div>
            {HistoryLoading ? 
            <div className="Month flex flex-1 flex-col" ref={HistoryMonthTopMB}>
              <div className="flex h-5"></div>
              {HistoryDb.map(history=>(
                <div className="Months flex flex-col h-full w-full" key={history.index} id={history.Year} name={history.Decade} >
                  <div className="flex">{history.Year}.{history.Month}</div>
                  <div className="flex h-0.5"></div>
                  <div className="flex flex-1 items-center">{history.Detail}</div>
                </div>
              ))}
            </div>
            : null}
          </div>
        </div>
        <div className="AboutUs_MB-Ogz flex flex-col w-full">
          <div className="flex h-36"><a name="groupmb"></a></div>
          <div className="flex w-full">조직도</div>
          <div className="flex h-8"></div>
          <div className="Pictures1 flex flex-col w-full">
            <img src="https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/AboutUs%2F%EC%A1%B0%EC%A7%81%EB%8F%84%20color%20red-01.png?alt=media&token=efcf6912-84a5-4e55-9840-ee0d0326806a" alt="조직도1"></img>
            <div className="flex w-full">
              <img className="flex w-1/2 mr-1" src="https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/AboutUs%2F%EC%A1%B0%EC%A7%81%EB%8F%84%20color%20red-02.png?alt=media&token=53810c68-887b-4ddf-ba03-1037c495c218" alt="조직도2"></img>
              <img className="flex w-1/2 ml-1" src="https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/AboutUs%2F%EC%A1%B0%EC%A7%81%EB%8F%84%20color%20red-03.png?alt=media&token=6deea132-2ac0-4c50-901b-8606e3815808" alt="조직도3"></img>
            </div>
          </div>
          
        </div>
        <div className="AboutUs_MB-Customer con flex flex-col w-full">
          <div className="flex h-36"><a name="customermb"></a></div>
          <div className="flex w-full">주요거래처</div>
          <div className="flex h-10"></div>
          {CustomerLoading ?
          <div className="flex flex-col w-full justify-center gap-4">
            {CustomerDb.map(customer=>(
              <div className="AboutUs_MB-Customer-lists flex flex-col items-center" key={customer.index}>
                <div className="flex w-full justify-center items-center">
                  <img src={customer.IMG} alt={customer.Name}></img>
                </div>
                <div className="flex w-full justify-center mt-2 mb-1">{customer.Name}</div>
              </div>
            ))}
          </div>
          : null}
        </div>
        <div className="flex h-10"></div>
        <div className="flex h-20"></div>

      </div>
    </div>
  );
}

export default AboutUs;
