import { React, useEffect, useRef, useState } from "react";
import './Research.css';
import { Link } from "react-router-dom";
import { getFirestore, collection, getDocs, doc, query, orderBy, limit , where} from "firebase/firestore"; // 데이터 읽어오기

function Research(props) {

  return (
    <div className="Research">
      <div className="Research_PC flex flex-col w-full items-center">
        <div className="Research_PC-banner flex flex-col w-full items-center">
          <div className="flex con w-full h-full flex-col justify-end">
            <div className="Research_PC-banner-top flex flex-col w-full justify-end">
              <div>Research &<br></br>Development</div>
              <div className="text-2xl pl-2">연구·개발</div>
            </div>
            <div className="flex h-14"></div>
            <div className="Research_PC-banner-menu flex w-full justify-end">
              <ul className="flex">
              <li className="flex flex-1 h-full justify-center">
                  <a href="#research">연구소 소개</a>
                </li>
                <li className="flex flex-1 h-full justify-center">
                  <a href="#development">주요개발품</a>
                </li>
              </ul>
            </div>
            <div className="flex h-6"></div>
          </div>
        </div>
        <div className="Research_PC-Intro con flex w-full flex-col">
          <div className="flex h-24"><a name="research"></a></div>
          <div className="flex w-full">연구소 소개</div>
          <div className="flex h-4"></div>
          <div className="Contents flex w-full">㈜신아화스텍은 글로벌 수준의 기술 경쟁력 확보를 위해 품질 향상과 더불어
            신사업 발굴을 목적으로 연구개발에 매진하고 있습니다.</div>
        </div>
        <div className="Research_PC-Products flex con w-full flex-col items-center">
        <div className="flex h-24"><a name="development"></a></div>
          <div className="flex con w-full">주요개발품</div>
          <div className="flex h-14"></div>
          <div className="Contents flex flex-col w-full">
            <div className="Contents-Title flex">윙 헤드레스트 &#40;Wing Headrest&#41;</div>
            <div className="flex h-5"></div>
            <div className="Contents-List flex flex-col w-full">
              <div className="flex flex-1 items-center">승차자의 머리 보호와 휴식 시 편의성을 확보하기 위한,<br></br>3단 각도조절이 가능한, WING HEADREST 제품입니다.<br></br>힌지 아세이라는 부품이 각도조절에 있어 핵심입니다.</div>
              <div className="flex w-full justify-center gap-12 mt-4">
                <div className="flex flex-col w-1/3 justify-center">
                  <img src="https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/Research%2F%E1%84%92%E1%85%B5%E1%86%AB%E1%84%8C%E1%85%B5-%E1%84%8B%E1%85%B5%E1%84%86%E1%85%B5%E1%84%8C%E1%85%B5%E1%84%8B%E1%85%AA%E1%86%AB%E1%84%89%E1%85%A5%E1%86%BC%E1%84%91%E1%85%AE%E1%86%B7%E1%84%8B%E1%85%B0%E1%86%B8%E1%84%8B%E1%85%AD%E1%86%BC.png?alt=media&token=c98801fd-58d7-409d-9c84-60b6f1ae5dc5" alt="윙 헤드레스트"></img>
                  <p className="flex justify-center mt-4">윙 헤드레스트 앞/뒷면</p>
                </div>
                <div className="flex flex-col w-1/3 justify-center">
                  <img src="https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/Research%2F%E1%84%92%E1%85%B5%E1%86%AB%E1%84%8C%E1%85%B5-%E1%84%92%E1%85%A6%E1%84%83%E1%85%B3%E1%84%85%E1%85%A6%E1%84%89%E1%85%B3%E1%84%90%E1%85%B3%E1%84%8C%E1%85%A5%E1%86%B8%E1%84%92%E1%85%B5%E1%86%B7.png?alt=media&token=a77a5c03-b26d-44a3-a173-b3500becbdac" alt="힌지 아세이"></img>
                  <p className="flex justify-center mt-4">힌지 아세이</p>
                </div>
                <div className="flex flex-col w-1/3 justify-center">
                  <img src="https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/Research%2F%E1%84%92%E1%85%B5%E1%86%AB%E1%84%8C%E1%85%B5-%E1%84%8B%E1%85%B5%E1%84%86%E1%85%B5%E1%84%8C%E1%85%B5-%E1%84%89%E1%85%B5%E1%86%AF%E1%84%8C%E1%85%A6%E1%84%8C%E1%85%A5%E1%86%A8%E1%84%8B%E1%85%AD%E1%86%BC.png?alt=media&token=fbcec3f6-5089-48c8-aad1-4692a9f6e73c" alt="실제적용"></img>
                  <p className="flex justify-center mt-4">실제 적용</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex h-20"></div>
          <div className="flex h-20"></div>
          <div className="Contents flex flex-col w-full">
            <div className="Contents-Title flex">싱크센스 국내유일 일체형 음식물처리기 &#40; SinkSense The only all-in-one disposer &#41;</div>
            <div className="flex h-20"></div>
            <div className="Contents-List flex flex-col w-full">
              <div className="Sinksense flex w-full">
                <img src="https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/Research%2F%E1%84%89%E1%85%B5%E1%86%BC%E1%84%8F%E1%85%B3%E1%84%89%E1%85%A6%E1%86%AB%E1%84%89%E1%85%B3-%E1%84%8B%E1%85%B5%E1%84%86%E1%85%B5%E1%84%8C%E1%85%B5%E1%84%8B%E1%85%B0%E1%86%B8%E1%84%8B%E1%85%AD%E1%86%BC.png?alt=media&token=8961ad4e-69e0-4e0c-b412-f75c8e32c801" alt="싱크센스"></img>
                <div className="flex flex-col flex-1 justify-center ml-10 pt-14">
                  <div className="flex">국내유일 <b>탑 오픈 방식 분리형 분쇄필터를 적용,</b></div>
                  <div className="flex">내부청소가 가능하도록 설게된 제품입니다.</div>
                  <div className="flex w-full justify-end gap-4 mt-8 pr-10">
                    <button><a>관련 홈페이지 바로가기</a></button>
                    <button><a>지금 구매하기</a></button>
                  </div>
                </div>
              </div>
              <div className="flex h-10"></div>
              <div className="Sinksense flex w-full">
                <div className="flex flex-col justify-center ml-20 mr-20 pt-10">
                  <div className="Contents-SubTitle flex mb-4">탑 오픈 방식 분리형 분쇄필터란,</div>
                  <p className="flex flex-col">
                    <b>기존 모든 분쇄형 음식물처리기의</b> 
                    1차&#40;분쇄&#41; 2차&#40;거름망&#41;처리기의 구조에서 쌓이는<br></br> 
                    2차 처리기의 음쓰 찌꺼기들을 싱크대 밑에서<br></br> 
                    주기적으로 청소해야 하는 불편함을 해소하고자<br></br> <br></br> 
                    싱크센스에서 개발한 
                    <b>1·2차 처리기를 하나로 합친<br></br> 
                    음식물쓰레기 분쇄 거름망&#40;필터&#41;특허기술입니다.</b><br></br>
                    필터가 본체로부터 분리되므로,<br></br>
                    싱크대 위에서 뚜껑만 열어서 제품 내부까지 청소가 가능합니다.
                  </p>
                </div>
                <img src="https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/Research%2F%E1%84%89%E1%85%B5%E1%86%BC%E1%84%8F%E1%85%B3%E1%84%89%E1%85%A6%E1%86%AB%E1%84%89%E1%85%B3-%E1%84%8B%E1%85%B5%E1%84%86%E1%85%B5%E1%84%8C%E1%85%B5%E1%84%91%E1%85%B5%E1%86%AF%E1%84%90%E1%85%A5%E1%84%8B%E1%85%B0%E1%86%B8%E1%84%8B%E1%85%AD%E1%86%BC.png?alt=media&token=3c652054-7312-487e-91e4-3c306ae87ae0" alt="싱크센스 필터"></img>
              </div>
              <div className="flex h-20"></div>
              <div className="flex w-full">
                <img src="https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/Research%2F%E1%84%89%E1%85%B5%E1%86%BC%E1%84%8F%E1%85%B3%E1%84%89%E1%85%A6%E1%86%AB%E1%84%89%E1%85%B3-%E1%84%8B%E1%85%B5%E1%84%86%E1%85%B5%E1%84%8C%E1%85%B5%E1%84%86%E1%85%A9%E1%84%90%E1%85%A5%E1%84%8B%E1%85%B0%E1%86%B8%E1%84%8B%E1%85%AD%E1%86%BC.png?alt=media&token=bb6165de-0eed-42fb-96ed-80302d46c860" alt="싱크센스 모터샤프트"></img>
                <div className="flex flex-col justify-center ml-20 mr-20 pt-10">
                  <div className="Contents-SubTitle flex mb-4">모터 샤프트</div>
                  <p className="flex flex-col">
                    <b>국내유일 일체형 음식물처리기 "싱크센스"의</b> 
                    흔들림과 떨림이 없는 안정적인 작동을 보장해주는<br></br> 
                    핵심모터 축입니다.<br></br> 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex h-40"></div>
        <div className="flex h-20"></div>
      </div>
      <div className="Research_MB flex flex-col w-full items-center">
        <div className="Research_MB-banner flex flex-col w-full items-center">
          <div className="flex con w-full h-full flex-col justify-end">
            <div className="Research_MB-banner-top flex flex-col w-full justify-end">
              <div>Research &<br></br>Development</div>
              <div className="text-2xl pl-2">연구·개발</div>
            </div>
            <div className="flex h-10"></div>
            <div className="Research_MB-banner-menu flex w-full justify-end">
              <ul className="flex">
              <li className="flex flex-1 h-full justify-center">
                  <a href="#researchMB">연구소 소개</a>
                </li>
                <li className="flex flex-1 h-full justify-center">
                  <a href="#developmentMB">주요개발품</a>
                </li>
              </ul>
            </div>
            <div className="flex h-6"></div>
          </div>
        </div>
        <div className="Research_MB-Intro con flex w-full flex-col">
          <div className="flex h-20"><a name="researchMB"></a></div>
          <div className="flex w-full">연구소 소개</div>
          <div className="flex h-4"></div>
          <div className="Contents flex w-full">㈜신아화스텍은 글로벌 수준의 기술 경쟁력 확보를 위해 품질 향상과 더불어
            신사업 발굴을 목적으로 연구개발에 매진하고 있습니다.</div>
        </div>
        <div className="Research_MB-Products flex con w-full flex-col items-center">
        <div className="flex h-20"><a name="developmentMB"></a></div>
          <div className="flex con w-full">주요개발품</div>
          <div className="flex h-10"></div>
          <div className="Contents flex flex-col w-full">
            <div className="Contents-Title flex">윙 헤드레스트 &#40;Wing Headrest&#41;</div>
            <div className="flex h-3"></div>
            <div className="Contents-List flex flex-col w-full">
              <div className="flex flex-1 items-center">승차자의 머리 보호와 휴식 시 편의성을 확보하기 위한,<br></br>3단 각도조절이 가능한, WING HEADREST 제품입니다.<br></br>힌지 아세이라는 부품이 각도조절에 있어 핵심입니다.</div>
              <div className="flex w-full flex-col justify-center mt-4">
                <div className="flex flex-col justify-center">
                  <img src="https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/Research%2F%E1%84%92%E1%85%B5%E1%86%AB%E1%84%8C%E1%85%B5-%E1%84%8B%E1%85%B5%E1%84%86%E1%85%B5%E1%84%8C%E1%85%B5%E1%84%8B%E1%85%AA%E1%86%AB%E1%84%89%E1%85%A5%E1%86%BC%E1%84%91%E1%85%AE%E1%86%B7%E1%84%8B%E1%85%B0%E1%86%B8%E1%84%8B%E1%85%AD%E1%86%BC.png?alt=media&token=c98801fd-58d7-409d-9c84-60b6f1ae5dc5" alt="윙 헤드레스트"></img>
                  <p className="flex justify-center mt-4">윙 헤드레스트 앞/뒷면</p>
                </div>
                <div className="flex h-8"></div>
                <div className="flex flex-col justify-center">
                  <img src="https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/Research%2F%E1%84%92%E1%85%B5%E1%86%AB%E1%84%8C%E1%85%B5-%E1%84%92%E1%85%A6%E1%84%83%E1%85%B3%E1%84%85%E1%85%A6%E1%84%89%E1%85%B3%E1%84%90%E1%85%B3%E1%84%8C%E1%85%A5%E1%86%B8%E1%84%92%E1%85%B5%E1%86%B7.png?alt=media&token=a77a5c03-b26d-44a3-a173-b3500becbdac" alt="힌지 아세이"></img>
                  <p className="flex justify-center mt-4">힌지 아세이</p>
                </div>
                <div className="flex flex-col justify-center">
                  <img src="https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/Research%2F%E1%84%92%E1%85%B5%E1%86%AB%E1%84%8C%E1%85%B5-%E1%84%8B%E1%85%B5%E1%84%86%E1%85%B5%E1%84%8C%E1%85%B5-%E1%84%89%E1%85%B5%E1%86%AF%E1%84%8C%E1%85%A6%E1%84%8C%E1%85%A5%E1%86%A8%E1%84%8B%E1%85%AD%E1%86%BC.png?alt=media&token=fbcec3f6-5089-48c8-aad1-4692a9f6e73c" alt="실제 적용"></img>
                  <p className="flex justify-center mt-4">실제 적용</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex h-20"></div>
          <div className="flex h-10"></div>
          <div className="Contents flex flex-col w-full">
            <div className="Contents-Title flex">싱크센스 국내유일 일체형 음식물처리기 &#40; SinkSense The only all-in-one disposer &#41;</div>
            <div className="flex h-2"></div>
            <div className="Contents-List flex flex-col w-full">
              <div className="Sinksense flex flex-col w-full">
                <img src="https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/Research%2F%E1%84%89%E1%85%B5%E1%86%BC%E1%84%8F%E1%85%B3%E1%84%89%E1%85%A6%E1%86%AB%E1%84%89%E1%85%B3-%E1%84%8B%E1%85%B5%E1%84%86%E1%85%B5%E1%84%8C%E1%85%B5%E1%84%8B%E1%85%B0%E1%86%B8%E1%84%8B%E1%85%AD%E1%86%BC.png?alt=media&token=8961ad4e-69e0-4e0c-b412-f75c8e32c801" alt="싱크센스"></img>
                <div className="flex flex-col flex-1 justify-center mt-4">
                  <div className="flex">국내유일 <b>탑 오픈 방식 분리형 분쇄필터를 적용,</b></div>
                  <div className="flex">내부청소가 가능하도록 설게된 제품입니다.</div>
                  <div className="flex w-full justify-end gap-4 mt-6 pr-2.5">
                    <button><a>관련 홈페이지 바로가기</a></button>
                    <button><a>지금 구매하기</a></button>
                  </div>
                </div>
              </div>
              <div className="flex h-10"></div>
              <div className="Sinksense flex w-full flex-col">
                <div className="flex flex-col justify-center pt-10">
                  <div className="Contents-SubTitle flex mb-3">탑 오픈 방식 분리형 분쇄필터란,</div>
                  <p className="flex flex-col">
                    <b>기존 모든 분쇄형 음식물처리기의</b> 
                    1차&#40;분쇄&#41; 2차&#40;거름망&#41;처리기의 구조에서 쌓이는<br></br> 
                    2차 처리기의 음쓰 찌꺼기들을 싱크대 밑에서<br></br> 
                    주기적으로 청소해야 하는 불편함을 해소하고자<br></br> <br></br> 
                    싱크센스에서 개발한 <b>1·2차 처리기를 하나로 합친<br></br> 
                    음식물쓰레기 분쇄 거름망&#40;필터&#41;특허기술입니다.</b> <br></br>
                    필터가 본체로부터 분리되므로,<br></br>
                    싱크대 위에서 뚜껑만 열어서 제품 내부까지 청소가 가능합니다.
                  </p>
                </div>
                <img src="https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/Research%2F%E1%84%89%E1%85%B5%E1%86%BC%E1%84%8F%E1%85%B3%E1%84%89%E1%85%A6%E1%86%AB%E1%84%89%E1%85%B3-%E1%84%8B%E1%85%B5%E1%84%86%E1%85%B5%E1%84%8C%E1%85%B5%E1%84%91%E1%85%B5%E1%86%AF%E1%84%90%E1%85%A5%E1%84%8B%E1%85%B0%E1%86%B8%E1%84%8B%E1%85%AD%E1%86%BC.png?alt=media&token=3c652054-7312-487e-91e4-3c306ae87ae0" alt="싱크센스 필터"></img>
              </div>
              <div className="flex h-4"></div>
              <div className="flex flex-col w-full">
                <div className="flex flex-col justify-center pt-10">
                  <div className="Contents-SubTitle flex mb-3">모터 샤프트</div>
                  <p className="flex flex-col">
                    <b>국내유일 일체형 음식물처리기 "싱크센스"의</b> 
                    흔들림과 떨림이 없는 안정적인 작동을 보장해주는<br></br> 
                    핵심모터 축입니다.<br></br> 
                  </p>
                </div>
                <img src="https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/Research%2F%E1%84%89%E1%85%B5%E1%86%BC%E1%84%8F%E1%85%B3%E1%84%89%E1%85%A6%E1%86%AB%E1%84%89%E1%85%B3-%E1%84%8B%E1%85%B5%E1%84%86%E1%85%B5%E1%84%8C%E1%85%B5%E1%84%86%E1%85%A9%E1%84%90%E1%85%A5%E1%84%8B%E1%85%B0%E1%86%B8%E1%84%8B%E1%85%AD%E1%86%BC.png?alt=media&token=bb6165de-0eed-42fb-96ed-80302d46c860" alt="싱크센스 모터샤프트"></img>
              </div>
            </div>
          </div>
        </div>
        <div className="flex h-10"></div>
        <div className="flex h-20"></div>
      </div>
    </div>
  );
}

export default Research;
