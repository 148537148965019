import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import $ from 'jquery';

import './Menu.css';

function Menu() {

  const[MBmenubar, SetMBmenubar]=useState(false);
  const MBmenus = useRef(null);

  const sideOutput = () => {
    if(MBmenus.current.nextElementSibling.classList.contains('Output')){
      MBmenus.current.nextElementSibling.classList.remove('Output');
      SetMBmenubar(false);
    }
    else{
      MBmenus.current.nextElementSibling.classList.add('Output');
      SetMBmenubar(true);
    }
  }

  function linkClicked(){
    if(MBmenus.current.nextElementSibling.classList.contains('Output')){
      MBmenus.current.nextElementSibling.classList.remove('Output');
      SetMBmenubar(false);
    }

    $(window).scrollTop(0);
    console.clear();
  }

  $(window).on('mousewheel',function(e){
      var wheel = e.originalEvent.wheelDelta;
      if(wheel>0){
        $(".Menu").stop().slideDown(500);
      } 
      else {
        $(".Menu").stop().slideUp(500);
      }     
  });

  return (
    <div className="Menu">
      <div className="Menu_PC flex flex-col w-full items-center">
        <div className="Menu_PC-1line flex h-full items-center">
          <div className="Menu_PC-left flex justify-start">
            <Link to="" onClick={linkClicked}><img src="./img/mainLogo.png" alt="메인로고" /></Link>
          </div>
          <div className="flex flex-1"></div>
          <ul className="Menu_PC-right flex h-full">
            <li className="flex flex-1 justify-center items-center">
              <Link to="/AboutUs" onClick={linkClicked}>회사소개</Link>
            </li>
            <li className="flex flex-1 justify-center items-center">
              <Link to="/Product" onClick={linkClicked}>생산</Link>
            </li>
            <li className="flex flex-1 justify-center items-center">
              <Link to="/Research" onClick={linkClicked}>R&D</Link>
            </li>
            <li className="flex flex-1 justify-center items-center">
              <Link to="/News" onClick={linkClicked}>고객지원</Link>
            </li>
            <div className="Menu_PC-bottom flex justify-center">
              <div className="Menu_PC-bottom-group flex">
                <div className="flex flex-1"></div>
                <div className="Menu_PC-bottom-GMenu flex flex-col items-center h-full">
                  <div className="flex flex-1 justify-center"><Link to="/AboutUs" onClick={linkClicked}>회사소개</Link></div>
                  <div className="flex flex-1 justify-center"><Link to="/Location" onClick={linkClicked}>사업장소개</Link></div>
                  <div className="flex flex-1 justify-center"></div>
                </div>
                <div className="Menu_PC-bottom-GMenu flex flex-col items-center h-full">
                  <div className="flex flex-1 justify-center"><Link to="/Product" onClick={linkClicked}>제품소개</Link></div>
                  <div className="flex flex-1 justify-center"><Link to="/Process" onClick={linkClicked}>공정소개</Link></div>
                  <div className="flex flex-1 justify-center"><Link to="/Facility" onClick={linkClicked}>설비현황</Link></div>
                </div>
                <div className="Menu_PC-bottom-GMenu flex flex-col items-center h-full">
                  <div className="flex flex-1 justify-center"><Link to="/Research" onClick={linkClicked}>R&D</Link></div>
                  <div className="flex flex-1 justify-center"><Link to="/Patent" onClick={linkClicked}>특허·인증</Link></div>
                  <div className="flex flex-1 justify-center"></div>
                </div>
                <div className="Menu_PC-bottom-GMenu flex flex-col items-center h-full">
                  <div className="flex flex-1 justify-center"><Link to="/News" onClick={linkClicked}>News</Link></div>
                  <div className="flex flex-1 justify-center"><Link to="/Contact" onClick={linkClicked}>Contact</Link></div>
                  <div className="flex flex-1 justify-center"><Link to="/Archaive" onClick={linkClicked}>홍보자료실</Link></div>
                </div>
              </div>
            </div>
          </ul>
        </div>
        
      </div>

      <div className="Menu_MB flex w-full items-center">
        <div className="Menu_MB-left flex">
          <Link to="" onClick={linkClicked}><img src="./img/mainLogo.png" alt="메인로고" /></Link>
        </div>
        <div className="flex flex-1"></div>
        <div className="Menu_MB-right flex justify-end items-center" onClick={sideOutput} ref={MBmenus}>
          {MBmenubar ? 
          <i className="fa-solid fa-xmark"></i>
          : 
          <i className="fa-solid fa-bars"></i>
          }
        </div>
        <div className="Menu_MB-lists flex">
          <ul className="Menu_MB-lists-menu flex flex-1 flex-col">
            <li className="flex flex-col">
              <Link to="/AboutUs" onClick={linkClicked}>회사소개</Link>
              <ul className="flex flex-col w-full">
                <div className="flex h-1"></div>
                <li className="flex"><Link to="/AboutUs" onClick={linkClicked}>회사소개</Link></li>
                <li className="flex"><Link to="/Location" onClick={linkClicked}>사업장소개</Link></li>
              </ul>
            </li>
            <li className="flex flex-col">
              <Link to="/Product" onClick={linkClicked}>생산</Link>
              <ul className="flex flex-col w-full">
                <div className="flex h-1"></div>
                <li className="flex"><Link to="/Product" onClick={linkClicked}>제품소개</Link></li>
                <li className="flex"><Link to="/Process" onClick={linkClicked}>공정소개</Link></li>
                <li className="flex"><Link to="/Facility" onClick={linkClicked}>설비현황</Link></li>
              </ul>
            </li>
            <li className="flex flex-col">
              <Link to="/Research" onClick={linkClicked}>R&D</Link>
              <ul className="flex flex-col w-full">
                <div className="flex h-1"></div>
                <li className="flex"><Link to="/Research" onClick={linkClicked}>연구개발</Link></li>
                <li className="flex"><Link to="/Patent" onClick={linkClicked}>특허·인증</Link></li>
              </ul>
            </li>
            <li className="flex flex-col">
              <Link to="/News" onClick={linkClicked}>고객지원</Link>
              <ul className="flex flex-col w-full">
                <div className="flex h-1"></div>
                <li className="flex"><Link to="/News" onClick={linkClicked}>News</Link></li>
                <li className="flex"><Link to="/Contact" onClick={linkClicked}>Contact</Link></li>
                <li className="flex"><Link to="/Archaive" onClick={linkClicked}>홍보자료실</Link></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Menu;
